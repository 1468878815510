import { DateTime } from 'luxon'

export const startFestival = window.__ENV__.APP_START_FESTIVAL ? window.__ENV__.APP_START_FESTIVAL : '2022-01-15'
export const nbDaysBefore = window.__ENV__.APP_NB_DAYS_BEFORE ? window.__ENV__.APP_NB_DAYS_BEFORE : 2
export const nbDaysLong = window.__ENV__.APP_NB_DAYS_LONG ? window.__ENV__.APP_NB_DAYS_LONG : 2
export const nbDaysAfter = window.__ENV__.APP_NB_DAYS_AFTER ? window.__ENV__.APP_NB_DAYS_AFTER : 1

export const arrivalDays: string[] = [/* 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche', 'inconnu' */]
export const festivalDays: string[] = [/* 'wen', 'sat', 'sun' */]
export const departureDays: string[] = [/* 'samedi', 'dimanche', 'lundi', 'inconnu' */]

const arrivalAndDepartureFormat = 'cccc'
const dateKeysFormat = 'ccc'
const datesFormat = 'cccc'
const isoDatesFormat = 'yyyy-MM-dd'

const dates: {[id: string]: string} = {
    'mon': 'lundi',
    'tue': 'mardi',
    'wen': 'mercredi',
    'thu': 'jeudi',
    'fri': 'vendredi',
    'sat': 'samedi',
    'sun': 'dimanche',
}

const isoDates: {[id: string]: string} = {
    //'wen': '2020-02-26',
    //'thu': '2020-02-27',
    //'fri': '2020-02-28',
    //'sat': '2020-02-29',
    //'sun': '2020-03-01',
    //'mon': '2020-03-02',
}

const firstDay = DateTime.fromISO(startFestival)

const initDates = () => {

    // Before
    for (let i = nbDaysBefore; i > 0; i--) {
        const d = firstDay.minus({days: i})
        const text = d.toFormat(arrivalAndDepartureFormat, { locale: 'fr' })
        const key = d.toFormat(dateKeysFormat, { locale: 'en' }).toLowerCase()
        const isoDate = d.toFormat(isoDatesFormat)

        arrivalDays.push(text)
        isoDates[key] = isoDate
    }

    // During
    for (let i = 0; i < nbDaysLong; i++) {
        const d = firstDay.plus({days: i})
        const text = d.toFormat(arrivalAndDepartureFormat, { locale: 'fr' })
        const key = d.toFormat(dateKeysFormat, { locale: 'en' }).toLowerCase()
        const isoDate = d.toFormat(isoDatesFormat)

        arrivalDays.push(text)
        festivalDays.push(key)
        departureDays.push(text)
        isoDates[key] = isoDate
    }

    // After
    for (let i = 0; i < nbDaysAfter; i++) {
        const d = firstDay.plus({days: i + nbDaysLong})
        const text = d.toFormat(arrivalAndDepartureFormat, { locale: 'fr' })
        const key = d.toFormat(dateKeysFormat, { locale: 'en' }).toLowerCase()
        const isoDate = d.toFormat(isoDatesFormat)

        departureDays.push(text)
        isoDates[key] = isoDate
    }

    arrivalDays.push('inconnu')
    departureDays.push('inconnu')

    //console.log(arrivalDays)
    //console.log(departureDays)
    //console.log(dates)
    //console.log(isoDates)
}

initDates()

export const getDay = (date: string) => {
    if (date == 'today') {
        date = DateTime.now().toFormat(dateKeysFormat).toLowerCase()
    }

    for (const day in dates) {
        if (day === date) {
            return dates[day]
        }
    }
}

export const getISODate = (date: string): string | undefined => {
    for (const day in isoDates) {
        if (day === date) {
            return isoDates[day]
        }
    }
}
