import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Badge,
    Button,
    Form, InputGroup, InputGroupAddon, Input,
    Table,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Spinner
} from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { Navigator } from '../navigation'
import { Guest } from './guest'
import { GuestListItem } from './list-item'
import { GuestStore } from './store'
import { SecurityStore } from '../security'

interface Props {
    navigator?: Navigator;
    guestStore?: GuestStore;
    security?: SecurityStore;
}

@inject('guestStore', 'security', 'navigator')
@observer
export default class GuestList extends React.Component<Props, {}> {
    protected searchInput: React.RefObject<HTMLInputElement>
    protected creatingNameInput: React.RefObject<HTMLInputElement>
    constructor (props: Props) {
        super(props)

        this.searchInput = React.createRef<HTMLInputElement>()
        this.creatingNameInput = React.createRef<HTMLInputElement>()

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'Insert':
        case 'Delete':
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let max: number
        let selectedIndex: number

        switch(code) {
        case 'Enter':
            if (this.props.guestStore) {
                const guests = this.filterAndOrderedGuests(this.props.guestStore.guests)

                if (this.props.guestStore.selectedIndex !== null && this.props.guestStore.selectedIndex >= 0 && this.props.guestStore.selectedIndex < guests.length) {
                    this.props.navigator.redirectTo('/guest/' + guests[this.props.guestStore.selectedIndex].id)
                    break
                }

                if (guests.length === 1) {
                    this.props.navigator.redirectTo('/guest/' + guests[0].id)
                }
            }
            break
        case 'Insert':
            if (this.searchInput.current) {
                this.searchInput.current.focus()
            }
            break
        case 'Delete':
            if (this.props.guestStore) {
                this.props.guestStore.setSearchText('')
            }
            break
        case 'Home':
            if (this.props.guestStore) {
                this.props.guestStore.setSelectedIndex(0)
            }
            break
        case 'End':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests).length - 1
                this.props.guestStore.setSelectedIndex(max)
            }
            break
        case 'ArrowUp':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests).length - 1
                selectedIndex = Math.max(0, this.props.guestStore.selectedIndex === null ? max : this.props.guestStore.selectedIndex - 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        case 'ArrowDown':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests).length - 1
                selectedIndex = Math.min(max, this.props.guestStore.selectedIndex === null ? 0 : this.props.guestStore.selectedIndex + 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        }
    }

    componentDidMount() {
        if (this.searchInput.current && !isMobile) {
            this.searchInput.current.focus()
        }
    }

    render() {
        const { guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        const guests = this.filterAndOrderedGuests(guestStore.guests)

        const identityGranted = security.isGranted('guest.data.identity')
        const presentGranted = security.isGranted('guest.present')
        const guestShowGranted = security.isGranted('guest.show')
        const guestCreateGranted = security.isGranted('guest.create')

        return (
            <div className="mt-4">
                <Form className="my-2" onSubmit={this.onSubmitHandler}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <i className="mdi mdi-account-search-outline input-group-text"></i>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Nom, prénom, nom de scène"
                            onChange={this.onChangeHandler}
                            value={guestStore.searchText}
                            ref={this.searchInput}
                            />
                        { guestStore.searchText != '' && (
                            <InputGroupAddon addonType="append">
                                <Button
                                    onClick={() => guestStore.setSearchText('')}
                                    >
                                    <i className="mdi mdi-close"></i>
                                </Button>
                            </InputGroupAddon>
                        )}
                    </InputGroup>
                </Form>
                <div>
                    <div className="d-flex flex-row justify-content-around">
                        <h2>
                            Total liste
                            <Badge color="info" className="ml-3">
                                {guests.length}
                            </Badge>
                        </h2>
                        <h2>
                            Total
                            <Badge color="primary" className="ml-3">
                                {guestStore.guests.length}
                            </Badge>
                        </h2>
                        <h2>
                            Total présent
                            <Badge color="success" className="ml-3">
                                {guestStore.nbPresents}
                            </Badge>
                        </h2>
                        { guestCreateGranted && (
                            <>
                                <Button
                                    outline
                                    color="warning"
                                    className="border-0"
                                    onClick={this.onCreateClikcHandler}
                                    >
                                    <i className="mdi mdi-account-plus mr-2"></i>Créer
                                </Button>
                                <Modal isOpen={guestStore.creatingName !== undefined} toggle={this.onCancelCreatingClikcHandler}>
                                    <ModalHeader toggle={this.onCancelCreatingClikcHandler}>
                                        <i className="mdi mdi-account-plus mr-2"></i>
                                        Nouvel invité
                                    </ModalHeader>
                                    <ModalBody>
                                        <Form className="my-2" onSubmit={this.onConfirmCreatingClikcHandler}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    Nom
                                                </InputGroupAddon>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Nom, prénom, nom de scène"
                                                    onChange={this.onChangeCreatingNameHandler}
                                                    value={guestStore.creatingName != undefined ? guestStore.creatingName : ''}
                                                    ref={this.creatingNameInput}
                                                    />
                                            </InputGroup>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter className="d-flex justify-content-between">
                                        <Button
                                            color="info"
                                            onClick={this.onCancelCreatingClikcHandler}
                                            >
                                            Annuler
                                        </Button>
                                        <Button
                                            color="danger"
                                            disabled={!guestStore.creatingName || guestStore.creating}
                                            onClick={() => this.onConfirmCreatingClikcHandler(null)}
                                            >
                                            { guestStore.creating && (
                                                <Spinner className="mr-2"/>
                                            )}
                                            Créer
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        )}
                    </div>
                    { guests.length > 0 && (
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Identité
                                    </th>
                                    <th>
                                        Nom de scène
                                    </th>
                                    <th>
                                        Groupe
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { guests.map((guest: Guest, index: number) => (
                                    <GuestListItem key={guest.id} guest={guest} identityGranted={identityGranted} presentGranted={presentGranted} guestShowGranted={guestShowGranted} selected={index === guestStore.selectedIndex}/>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        )
    }

    onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.guestStore) {
            this.props.guestStore.setSearchText(event.target.value)
        }
    }

    onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
    }

    filterAndOrderedGuests (guests: Guest[]) {
        let _guests = guests.slice(0)

        if (this.props.guestStore && this.props.guestStore.searchText) {
            const searchText = this.props.guestStore.searchText.toLowerCase()
            _guests = _guests.filter((guest) => {
                return guest.stringKey.search(searchText) != -1
            })
        }

        _guests.sort((a, b) => {
            if (a.stringKey == b.stringKey) {
                return 0
            }

            return a.stringKey < b.stringKey ? -1 : 1
        })

        return _guests
    }


    onCreateClikcHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.setCreatingName('')
        }

        if (this.creatingNameInput.current) {
            this.creatingNameInput.current.focus()
        }
    }

    onCancelCreatingClikcHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.cancelCreating()
        }
    }

    onConfirmCreatingClikcHandler = (event: React.FormEvent<HTMLFormElement> | null) => {
        if (event) {
            event.preventDefault()
        }

        if (this.props.guestStore) {
            this.props.guestStore.create()
        }
    }

    onChangeCreatingNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.guestStore) {
            this.props.guestStore.setCreatingName(event.target.value)
        }
    }
}
