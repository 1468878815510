import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import {
    CustomInput,
    ListGroupItem
} from 'reactstrap'
import { SecuredView } from '../components/secured-view'
import { GuestStore } from './store'
import { SecurityStore } from '../security'
import { Guest } from './guest'
import cn from 'classnames'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
    guest: Guest;
    identityGranted?: boolean;
    presentGranted?: boolean;
    guestShowGranted?: boolean;
    selected: boolean;
}

@inject('guestStore', 'security')
@observer
export class GuestListItem extends React.Component<Props, {}> {
    render() {
        const { guestStore, security, guest, identityGranted, presentGranted, guestShowGranted, selected } = this.props

        if (!guestStore || !security) {
            return null
        }

        const nbUnreadMessages = security.currentUser ? guest.getNbUnreadMessageBy(security.currentUser.login) : 0

        return (
            <tr className={cn({ 'font-weight-bold': selected, 'table-active': selected, 'text-warning': !guest.synchronized })}>
                <td>
                    { identityGranted ? (guest.data.identity.firstname + ' ' + guest.data.identity.lastname).trim() : (guest.name ? guest.name : 'xxx')}
                </td>
                <td>
                    { identityGranted ? guest.data.identity.stageName : ''}
                </td>
                <td>
                    { identityGranted ? guest.data.identity.group : ''}
                </td>
                <td>
                    <div className="d-flex justify-content-center align-items-end">
                        <span className={guest.isPresent ? 'text-success' : 'text-warning' }>
                            <i className={cn('mdi mdi-24px mr-3', { 'mdi-check': guest.isPresent, 'mdi-close': !guest.isPresent })}></i>
                        </span>
                        <Link
                            to={'/guest/'+guest.id}
                            className={cn('btn btn-secondary btn-sm', {'btn-warning': nbUnreadMessages > 0, 'disabled': !guestShowGranted})}
                            >
                            <i className="mdi mdi-eye"></i>
                        </Link>
                    </div>
                </td>
            </tr>
        )
    }

    onPresenceChangeHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.markPresent(this.props.guest, !this.props.guest.isPresent)
        }
    }
}
