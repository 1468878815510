import { observable, action, computed, reaction } from 'mobx'
import { Guest, SerializedGuest, MessageRead, Action, StateKey } from './guest'
import { Connection } from '../communication'
import { SecurityStore } from '../security'
import { Navigator } from '../navigation'

export class GuestStore {
    protected connection: Connection
    protected securityStore: SecurityStore
    protected navigator: Navigator
    @observable guests: Guest[] = []
    @observable searchText = ''
    @observable ready = false
    @observable selectedIndex: number | null = null
    @observable showManagedArrivals: boolean = false
    @observable showIndependentArrivals: boolean = false
    @observable showManagedDepartures: boolean = false
    @observable showIndependentDepartures: boolean = false

    @observable creatingName: string | undefined
    @observable creating: boolean = false

    constructor (connection: Connection, securityStore: SecurityStore, navigator: Navigator) {
        this.connection = connection
        this.securityStore = securityStore
        this.navigator = navigator

        this.addListenerToConnection()
    }

    @computed
    get nbPresents (): number {
        let c = 0
        for (const guest of this.guests) {
            c +=  (guest.isPresent) ? 1 : 0
        }

        return c
    }

    @action.bound
    updateGuest (guest: SerializedGuest) {
        if (guest) {
            const index = this.findGuestIndexById(guest.id)

            if (index >= 0) {
                this.guests[index].load(guest)
            } else {
                const g = new Guest(guest.id)
                g.load(guest)
                this.guests.push(g)

                if (this.creating) {
                    this.cancelCreating()
                    this.navigator.redirectTo('/guest/' + guest.id)
                }
            }
        }
    }

    @action
    setSearchText (text: string) {
        if (this.searchText !== text) {
            this.selectedIndex = null
        }
        this.searchText = text
    }

    @action
    setSelectedIndex (i: number | null) {
        this.selectedIndex = i
    }

    @action
    setShowManagedArrivals (v: boolean) {
        this.showManagedArrivals = v
    }

    @action
    setShowIndependentArrivals (v: boolean) {
        this.showIndependentArrivals = v
    }

    @action
    setShowManagedDepartures (v: boolean) {
        this.showManagedDepartures = v
    }

    @action
    setShowIndependentDepartures (v: boolean) {
        this.showIndependentDepartures = v
    }

    addListenerToConnection () {
        this.connection.on('server.error', (code) => {
            console.log(code)
        })

        this.connection.on('guest.show', (id, guest) => {
            this.updateGuest(guest)
        })

        this.connection.on('guest.list', this.onLoadFromServer)

        reaction(
            () => this.securityStore.isAuthenticated,
            this.refreshGuestList
        )
        this.refreshGuestList()

        this.connection.on('guest.updated', (id) => {
            this.connection.emit('guest.show', id)
        })
    }

    refreshGuestList = () => {
        if (this.securityStore.isAuthenticated) {
            this.connection.emit('guest.list')
        }
    }

    @action.bound
    onLoadFromServer = (guests: SerializedGuest[]) => {
        this.guests.splice(0)
        for (const guest of guests) {
            this.updateGuest(guest)
        }

        this.ready = true
    }

    findGuestIndexById (id: string): number {
        for (const index in this.guests) {
            if (this.guests[index].id === id) {
                return parseInt(index, 10)
            }
        }

        return -1
    }

    findGuestById (id: string): Guest | null {
        const index = this.findGuestIndexById(id)
        if (index < 0) {
            return null
        }

        return this.guests[index]
    }

    refresh () {
        if (this.connection.connected) {
            this.connection.emit('guest.fetch')
        }
    }

    /*getKey(id: string) {
        return 'guest.' + id.toString().padStart(3, '0')
    }*/

    @action
    markPresent (guest: Guest, isPresent: boolean) {
        guest.isPresent = isPresent

        this.connection.emitImportant('guest.present', guest.id, isPresent)
    }

    @action
    markArrivalManaged (guest: Guest, isArrivalManaged: boolean) {
        guest.isArrivalManaged = isArrivalManaged

        this.connection.emitImportant('guest.arrivalManaged', guest.id, isArrivalManaged)
    }

    @action
    markDepartureManaged (guest: Guest, isDepartureManaged: boolean) {
        guest.isDepartureManaged = isDepartureManaged

        this.connection.emitImportant('guest.departureManaged', guest.id, isDepartureManaged)
    }

    @action
    setCreatingName (name: string) {
        this.creatingName = name
    }

    @action
    cancelCreating () {
        this.creatingName = undefined
        this.creating = false
    }

    @action
    create () {
        if (!this.creatingName) {
            return
        }
        this.connection.emitImportant('guest.create', this.creatingName)

        this.creating = true
    }

    @action
    removeGuest (guest: Guest) {
        const index = this.findGuestIndexById(guest.id)

        if (index >= 0) {
            this.guests.splice(index, 1)
            /*AsyncStorage.removeItem(this.getKey(guest.id, (error) => {
                // Do nothing
            }))*/
        }

        this.connection.emitImportant('guest.remove', guest.id)
    }

    @action
    writeMessage (guest: Guest, content: string) {
        this.connection.emitImportant('guest.messageWrite', guest.id, content)

        return this.connection.connected
    }

    @action
    readMessage (guest: Guest, by: string, uuid: string, isRead: boolean) {
        if (!uuid) {
            return false
        }

        for (const message of guest.messages) {
            if (message.uuid === uuid) {
                const r: MessageRead = {
                    at: new Date(),
                    by: by
                }
                if (message.readBy === undefined) {
                    message.readBy = [r]
                } else {
                    message.readBy.push(r)
                }
            }
        }

        this.connection.emitImportant('guest.messageRead', guest.id, uuid, isRead)
    }

    @action
    upadetPicture (guest: Guest, picture: string) {
        guest.picture = picture

        this.connection.emitImportant('guest.updatePicture', guest.id, picture)
    }

    getNextArrivals (reference: string, limit = 5): Guest[] {

        const _g = this.guests.filter((guest) => {
            return !guest.isArrivalManaged && guest.arrivalDate > reference && (guest.data.travel && guest.data.travel.arrival.type !== 'independent')
        })

        _g.sort((a, b) => {
            if (a.arrivalDate === b.arrivalDate) {
                return 0
            }

            return a.arrivalDate < b.arrivalDate ? -1 : 1
        })

        return _g.slice(0, limit)
    }

    getNextDepartures (reference: string, limit = 5): Guest[] {

        const _g = this.guests.filter((guest) => {
            return !guest.isDepartureManaged && guest.departureDate > reference && (guest.data.travel && guest.data.travel.arrival.type !== 'independent')
        })

        _g.sort((a, b) => {
            if (a.departureDate === b.departureDate) {
                return 0
            }

            return a.departureDate < b.departureDate ? -1 : 1
        })

        return _g.slice(0, limit)
    }

    getLastActions (categories: StateKey[] = [], limit = 3): {action: Action; guest: Guest}[] {
        const _actions: {action: Action; guest: Guest}[] = []

        for (const guest of this.guests) {
            if (guest.actions.length > 0) {

                for (const action of guest.actions) {
                    if (categories.length === 0 || categories.indexOf(action.type) >=0) {
                        _actions.push({
                            action: action,
                            guest: guest
                        })
                    }
                }
            }
        }

        _actions.sort((a, b) => {
            if (a.action.at == b.action.at) {
                return 0
            }

            return a.action.at > b.action.at ? -1 : 1
        })

        return _actions.slice(0, limit)
    }
}
