import * as events from 'events'

export class Navigator extends events.EventEmitter {
    constructor () {
        super()
        if (document !== undefined) {
            document.addEventListener('keydown', this.onKeyDownHandler)
            document.addEventListener('keyup', this.onKeyUpHandler)
        }
    }

    onKeyDownHandler = (event: KeyboardEvent) => {
        this.emit('key-down', event.code, event)
        this.emit('key-down-' + event.code, event)
    }

    onKeyUpHandler = (event: KeyboardEvent) => {
        this.emit('key-up', event.code, event)
        this.emit('key-up-' + event.code, event)
    }

    redirectTo (path: string, exact: boolean = true) {
        this.emit('redirect', path, exact)
    }

    goBack () {
        this.emit('goBack')
    }
}
