import { observable, action, computed, reaction } from 'mobx'
import { Connection } from '../communication'
import * as uuid from 'uuid'

export class NotificationStore {
    protected connection: Connection
    @observable notifications: Notification[] = []

    constructor (connection: Connection) {
        this.connection = connection
    }

    public addNotificationError (text: string, callback?: () => void) {
        this.addNotification({
            title: 'Erreur',
            text: text,
            type: 'danger',
            callback: callback
        })
    }

    public addNotificationSuccess (text: string, callback?: () => void) {
        this.addNotification({
            title: 'Succès',
            text: text,
            type: 'success',
            callback: callback
        })
    }

    public addNotificationWarning (text: string, callback?: () => void) {
         this.addNotification({
            title: 'Avertissement',
            text: text,
            type: 'warning',
            callback: callback
        })
    }

    @action
    public addNotification (options: NotificationOptions) {
        const notification: Notification = {
            uuid: uuid.v4(),
            title: options.title,
            text: options.text,
            duration: options.duration ? options.duration : 3000,
            type: options.type ? options.type : 'info',
            callback: options.callback
        }

        this.notifications.push(notification)
    }

    @action
    public eraseNotification (uuid: string) {
        let index: number | null = null
        for (const i in this.notifications) {
            if (this.notifications[i].uuid === uuid) {
                index = parseInt(i, 10)
            }
        }

        if (index !== null) {
            const ns = this.notifications.splice(index, 1)
            for (const n of ns) {
                if (n.callback) {
                    n.callback()
                }
            }
        }
    }
}

export interface NotificationOptions {
    title: string;
    text: string;
    duration?: number;
    type?: 'success' | 'warning' | 'info' | 'danger';
    callback?: () => void;
}

export interface Notification {
    uuid: string;
    title: string;
    text: string;
    duration: number;
    type: 'success' | 'warning' | 'info' | 'danger';
    callback?: () => void;
}
