import * as React from 'react'
import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { observer } from 'mobx-react'
import { Notification } from './store'
import * as cn from 'classnames'

interface Props {
    notification: Notification;
    onRead?: () => void;
}

interface State {
    isOpen: boolean;
}

@observer
export default class NotificationItem extends React.Component<Props, State> {
    protected closeInterval: NodeJS.Timeout | null = null
    constructor (props: Props) {
        super(props)

        this.state = {
            isOpen: true
        }
    }

    componentDidMount () {
        this.initCloseTimeout()
    }

    componentWillUnmount () {
        this.resetCloseTimeout()
    }

    render () {
        const { notification } = this.props

        return (
            <Toast
                isOpen={this.state.isOpen}
                transition={{
                    onExited: this.readNotification,
                    timeout: 150
                }}
                onMouseEnter={this.onMouseEnterHandler}
                onMouseLeave={this.onMouseLeaveHandler}
                >
                <ToastHeader
                    icon={notification.type}
                    toggle={this.closeNotification}>
                    { notification.title }
                </ToastHeader>
                <ToastBody>
                    { notification.text }
                </ToastBody>
            </Toast>
        )
    }

    protected closeNotification = () => {
        this.setState({
            isOpen: false
        })
    }

    protected readNotification = () => {
        if (this.props.onRead) {
            this.props.onRead()
        }
    }

    protected onCloseIntervalHandler = () => {
        this.setState({
            isOpen: false
        })
    }

    protected initCloseTimeout () {
        this.resetCloseTimeout()

        this.closeInterval = setTimeout(
            this.onCloseIntervalHandler,
            this.props.notification.duration
        )
    }

    protected resetCloseTimeout () {
        if (this.closeInterval) {
            clearTimeout(this.closeInterval)
            this.closeInterval = null
        }
    }

    protected onMouseEnterHandler = () => {
        this.resetCloseTimeout()
    }

    protected onMouseLeaveHandler = () => {
        this.initCloseTimeout()
    }
}
