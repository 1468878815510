import { observable, action } from 'mobx'
import { Connection } from './connection'

export class CommunicationStore {
    protected connection: Connection
    @observable isConnected = false

    constructor (connection: Connection) {
        this.connection = connection
        this.connection.on('connect', this.onConnected.bind(this))
        this.connection.on('disconnect', this.onDisconnected.bind(this))
    }

    @action.bound
    onConnected () {
        this.isConnected = true
    }

    @action.bound
    onDisconnected () {
        this.isConnected = false
    }
}