import { observable } from 'mobx'

export class ImportantEvent {
    public uuid: string
    public event: string
    public args: any[]
    public by: string = ''
    public at: string
    public sendBy: string | null = null
    @observable sendAt: string | null = null

    constructor (event: string = 'tmp', args: any[] = []) {
        this.uuid = (new Date()).getTime()+'.'+Math.round(Math.random()*1000000)
        this.event = event
        this.args = args
        this.at = (new Date()).toISOString()
    }

    load(event: ImportantEvent) {
        this.uuid = event.uuid
        this.event = event.event
        this.args = event.args
        this.by = event.by
        this.at = event.at
        this.sendBy = event.sendBy
        this.sendAt = event.sendAt
    }
}
