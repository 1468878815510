import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Badge,
    Table
} from 'reactstrap'
import { Navigator } from '../navigation'
import { ArrivalListItem } from './arrival-list-item'
import { GuestStore, Guest } from '../guest'
import { getDay, arrivalDays } from '../util/days'

interface Props {
    navigator?: Navigator;
    guestStore?: GuestStore;
    identityGranted: boolean;
    arrivalManagedGranted: boolean;
    receptionGranted: boolean;
    managementGranted: boolean;
    guestShowGranted: boolean;
    day: string;
}

@inject('guestStore', 'navigator')
@observer
export class ArrivalListTab extends React.Component<Props, {}> {
    constructor (props: Props) {
        super(props)

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let max: number
        let selectedIndex: number

        switch(code) {
        case 'Enter':
            if (this.props.guestStore) {
                const arrivals = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day)

                if (this.props.guestStore.selectedIndex !== null && this.props.guestStore.selectedIndex >= 0 && this.props.guestStore.selectedIndex < arrivals.count) {
                    this.props.navigator.redirectTo('/guest/' + arrivals.list[this.props.guestStore.selectedIndex].id)
                    break
                }

                if (arrivals.count === 1) {
                    this.props.navigator.redirectTo('/guest/' + arrivals.list[0].id)
                }
            }
            break
        case 'Home':
            if (this.props.guestStore) {
                this.props.guestStore.setSelectedIndex(0)
            }
            break
        case 'End':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                this.props.guestStore.setSelectedIndex(max)
            }
            break
        case 'ArrowUp':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                selectedIndex = Math.max(0, this.props.guestStore.selectedIndex === null ? max : this.props.guestStore.selectedIndex - 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        case 'ArrowDown':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                selectedIndex = Math.min(max, this.props.guestStore.selectedIndex === null ? 0 : this.props.guestStore.selectedIndex + 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        }
    }

    render() {
        const { guestStore, identityGranted, arrivalManagedGranted, receptionGranted, managementGranted, guestShowGranted, day } = this.props

        if (!guestStore) {
            return null
        }

        const arrivals = this.filterAndOrderedGuests(guestStore.guests, day)

        return (
            <div>
                <div className="d-flex flex-row justify-content-around">
                    <h2>
                        Total
                        <Badge color="info" className="ml-3">
                            {arrivals.count}
                        </Badge>
                    </h2>
                    <h2>
                        Arrivé
                        <Badge color={arrivals.count == arrivals.nbManaged ? 'success' : 'primary'} className="ml-3">
                            {arrivals.nbManaged}
                        </Badge>
                    </h2>
                    <h2>
                        A venir
                        <Badge color={arrivals.count - arrivals.nbManaged >0 ? 'warning' : 'success'} className="ml-3">
                            {arrivals.count - arrivals.nbManaged}
                        </Badge>
                    </h2>
                </div>

                { arrivals.count === 0 ? (
                    <div className="alert alert-success m-5 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-trophy mdi-48px mr-5"></i>
                        Toutes les arrivées (qui comptent) de la journée sont gérées... quelle équipe formidable !!!
                    </div>
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Voyage
                                </th>
                                <th>
                                    Identité
                                </th>
                                <th>
                                    Nom de scène
                                </th>
                                <th>
                                    Groupe
                                </th>
                                { receptionGranted && (
                                    <th>
                                        Hébergement
                                    </th>
                                )}
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { arrivals.list.map((guest: Guest, index: number) => (
                                <ArrivalListItem
                                    key={index}
                                    guest={guest}
                                    identityGranted={identityGranted}
                                    arrivalManagedGranted={arrivalManagedGranted}
                                    receptionGranted={receptionGranted}
                                    managementGranted={managementGranted}
                                    guestShowGranted={guestShowGranted}
                                    showDay={day === 'inconnu'}
                                    selected={guestStore.selectedIndex === index}
                                    />
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        )
    }

    filterAndOrderedGuests (guests: Guest[], day: string) {

        const _guests = guests.filter((guest) => {
            if (guest.data.management.cancellation || !guest.data.travel || !guest.data.travel.arrival) {
                return false
            }
            let key = guest.data.travel.arrival.day ? getDay(guest.data.travel.arrival.day) : 'inconnu'
            if (!key) {
                key = 'inconnu'
            }

            if (arrivalDays.indexOf(key) < 0) {
                key = 'inconnu'
            }

            let isOpen = true

            if (this.props.guestStore) {
                if (guest.isArrivalManaged && !this.props.guestStore.showManagedArrivals) {
                    isOpen = false
                }

                if (guest.data.travel.arrival.type === 'independent' && !this.props.guestStore.showIndependentArrivals) {
                    isOpen = false
                }
            }

            if (!isOpen) {
                return false
            }

            return key === day
        })

        let nbManaged = 0
        _guests.map((guest) => {
            if (guest.isArrivalManaged) {
                nbManaged ++
            }
        })

        _guests.sort((a, b) => {
            if (a.data.travel.arrival.end == b.data.travel.arrival.end) {
                return 0
            }

            if (!a.data.travel.arrival.end) {
                return 1
            }

            if (!b.data.travel.arrival.end) {
                return -1
            }

            return a.data.travel.arrival.end < b.data.travel.arrival.end ? -1 : 1
        })

        return {
            list: _guests,
            nbManaged: nbManaged,
            count: _guests.length
        }
    }
}
