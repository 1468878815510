import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { dateFormat } from '../components/date-formatter'
import { generateTextAction } from '../util/actions'
import {
    ListGroupItem
} from 'reactstrap'
import { Guest, Action } from './guest'

interface Props {
    action: Action;
    guest: Guest;
}

@observer
export default class GuestShowActionsItem extends React.Component<Props, {}> {
    render() {
        const { action, guest } = this.props

        return (
            <ListGroupItem>
                {dateFormat(action.at)} : {generateTextAction(action, guest)}
            </ListGroupItem>
        )
    }
}
