import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
    Button,
    Col, Row,
    Card, CardHeader, CardBody,
    Form, FormGroup, Input, CustomInput, Label
} from 'reactstrap'
import { SecurityStore, User } from '../security'

interface Props extends RouteComponentProps<any> {
    security?: SecurityStore;
}

interface State {
    user: User | null;
    groups: string[];
    managers: string[];
    password: string;
    passwordError: boolean;
    redirect: boolean;
}

@inject('security')
@observer
export default class UserEdit extends React.Component<Props, State> {
    protected groups: Groups
    protected profils: Profil[]

    constructor(props: Props) {
        super(props)

        const user = props.security ? props.security.findUserById(props.match.params.id) : null

        this.state = {
            user: user,
            groups: user ? user.groups.slice(0) : [],
            managers: user && user.managers ? user.managers.slice(0) : [],
            password : '',
            passwordError: false,
            redirect: false,
        }

        this.groups = {
            'user': 'Gestion des utilisateurs',
            'guest.list': 'Liste des invités',
            'guest.show': 'Voir un invité',
            //'guest.create': 'Créer un invité',
            'guest.present': 'Marquer les invités présent/absent',
            'guest.arrivalManaged': 'Marquer l\'arrivée des invités gérée/non gérée',
            'guest.departureManaged': 'Marquer le départ des invités gérée/non gérée',
            'guest.updatePicture': 'Mettre à jour la photo de l\'invité',
            'guest.fetch': 'Mettre à jour la base invités (sur le server)',
            'guest.data.identity': 'Accès à l\'identité des invités',
            'guest.data.travel': 'Accès aux informations de voyages des invités',
            'guest.data.management': 'Accès aux informations d\'organisation',
            'guest.data.reception': 'Accès aux informations d\'accueil',
            'guest.actions': 'Accès aux actions sur une fiche invité',
            'guest.messageRead': 'Accès aux messages d\'organisation (lecture)',
            'guest.messageWrite': 'Accès aux messages d\'organisation (écriture)',
            'guest.receptionManaged': 'Accès au lieu d\'hébergement de l\'invité',
        }

        this.profils = [
            {
                'name': 'Manager',
                'groups': [
                    'user',
                    'guest.list',
                    'guest.show',
                    //'guest.create',
                    'guest.present',
                    'guest.fetch',
                    'guest.data.identity',
                    'guest.data.travel',
                    'guest.data.management',
                    'guest.data.reception',
                    'guest.arrivalManaged',
                    'guest.departureManaged',
                    'guest.updatePicture',
                    'guest.actions',
                    'guest.messageRead',
                    'guest.messageWrite',
                    'guest.receptionManaged',
                ],
            },
            {
                'name': 'Team invités',
                'groups': [
                    'guest.list',
                    'guest.show',
                    'guest.present',
                    'guest.data.identity',
                    'guest.data.travel',
                    'guest.data.management',
                    'guest.data.reception',
                    'guest.arrivalManaged',
                    'guest.departureManaged',
                    'guest.updatePicture',
                    'guest.actions',
                    'guest.messageRead',
                    'guest.messageWrite',
                ],
            },
            {
                'name': 'Spectateur',
                'groups': [
                    'guest.list',
                    'guest.show',
                    'guest.data.identity',
                    'guest.data.management',
                    'guest.messageRead',
                ],
            }
        ]
    }

    render() {
        const { user, groups, password, passwordError, redirect, managers } = this.state
        const { security } = this.props

        if (!user || redirect || !security) {
            return (
                <Redirect to="/system/user"/>
            )
        }

        return (
            <Form onSubmit={this.onSubmitHandler}>
                <Row className="mt-4 row-cols-2">
                    <Col className="mb-4">
                        <Card>
                            <CardHeader>
                                Identité
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Input
                                        value={user.login}
                                        disabled={true}
                                        />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder="Mot de passe (si vous souhaitez le modifier)"
                                        value={password}
                                        onChange={this.onChangePasswordHandler}
                                        invalid={passwordError}
                                        />
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mb-4">
                        <Card>
                            <CardHeader>
                                Filtrage des invités
                            </CardHeader>
                            <CardBody>
                                { security.managers.map((manager: string, index: number) => (
                                    <FormGroup key={manager} className="d-flex">
                                        <CustomInput
                                            id={'filter_' + manager}
                                            type="switch"
                                            checked={managers.indexOf(manager) >= 0}
                                            onChange={this.onChangeManagerHandler.bind(this, manager)}
                                            />
                                        <Label>{manager}</Label>
                                    </FormGroup>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mb-4">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <div>
                                    Permissions
                                </div>
                                <div className="d-flex justify-content-between">
                                    { this.profils.map((profil, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => this.setGroups(profil.groups)}
                                            className="ml-3"
                                            >
                                            {profil.name}
                                        </Button>
                                    ))}
                                </div>
                            </CardHeader>
                            <CardBody>
                                { Object.keys(this.groups).map((group) => (
                                    <FormGroup key={group} className="d-flex">
                                        <CustomInput
                                            id={'authorization_' + group}
                                            type="switch"
                                            checked={groups.indexOf(group) >= 0}
                                            onChange={this.onChangeGroupHandler.bind(this, group)}
                                            />
                                        <Label>{this.groups[group]}</Label>
                                    </FormGroup>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mb-4">
                        <Button
                            color="primary"
                            >
                            <i className="mdi mdi-content-save"></i>
                            Modifier
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }

    onChangePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: event.target.value
        })
    }

    onChangeGroupHandler (group: string) {
        const groups = this.state.groups
        const index = groups.indexOf(group)

        if (index < 0) {
            groups.push(group)
        } else {
            groups.splice(index, 1)
        }

        this.setState({groups: groups})
    }

    onChangeManagerHandler (manager: string) {
        const managers = this.state.managers
        const index = managers.indexOf(manager)

        if (index < 0) {
            managers.push(manager)
        } else {
            managers.splice(index, 1)
        }

        this.setState({managers: managers})
    }

    setGroups (groups: string[]) {
        this.setState({'groups': groups})
    }

    onSubmitHandler = (event: React.FormEvent) => {
        event.preventDefault()
        if (this.state.password && this.props.security && this.state.user) {
            this.props.security.changeUserPassword(this.state.user.login, this.state.password)
        }

        if (this.props.security && this.state.user) {
            this.props.security.changeUserGroups(this.state.user.login, this.state.groups)
        }

        if (this.props.security && this.state.user) {
            this.props.security.changeUserManagers(this.state.user.login, this.state.managers)
        }

        this.setState({
            redirect: true
        })
    }
}

interface Groups {
    [id: string]: string;
}

interface Profil {
    name: string;
    groups: string[];
}
