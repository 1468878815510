import * as React from 'react'
import { inject, observer } from 'mobx-react'
import {
    NavItem, NavLink
} from 'reactstrap'
import { SecurityStore } from './store'

interface Props {
    security?: SecurityStore;
}

@inject('security')
@observer
export default class SecurityActions extends React.Component<Props, {}> {
    render() {
        const { security } = this.props

        if (!security) {
            return null
        }

        return (
            <NavItem>
                <NavLink onClick={() => security.disconnect()}>
                    <i className="mdi mdi-logout mr-2"></i>
                    Déconnection
                </NavLink>
            </NavItem>
        )
    }
}
