import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationStore, Notification } from './store'
import * as cn from 'classnames'
import NotificationItem from './notification-item'

interface Props {
    notificationStore?: NotificationStore;
}

interface State {}

@inject('notificationStore')
@observer
export default class Notificator extends React.Component<Props, State> {
    render () {
        const { notificationStore } = this.props

        if (!notificationStore) {
            return <></>
        }

        return (
            <div className="fixed-bottom m-2">
                { notificationStore.notifications.map((notification: Notification) => {
                    return (
                        <NotificationItem
                            key={notification.uuid}
                            notification={notification}
                            onRead={this.readNotification.bind(this, notification)}
                            />
                    )
                })}
            </div>
        )
    }

    protected readNotification (notification: Notification) {
        if (this.props.notificationStore) {
            this.props.notificationStore.eraseNotification(notification.uuid)
        }
    }
}
