import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Button,
    Card, CardHeader, CardBody,
    Col,
    ListGroup, ListGroupItem,
    Modal, ModalHeader, ModalBody
} from 'reactstrap'
import GuestShowActionsItem from './show_actions_item'
import { GuestStore } from './store'
import { SecurityStore } from '../security'
import { Guest, Action } from './guest'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
    guest: Guest;
}

interface State {
    isOpen: boolean;
}

@inject('security', 'guestStore')
@observer
export default class GuestShowActions extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props)

        this.state = {
            isOpen: false
        }
    }

    render() {
        const { guest, guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        const actionsGranted = security.isGranted('guest.actions')

        if (!actionsGranted) {
            return null
        }

        const actions = this.filterAndOrderedActions(guest.actions)

        const lastActions = actions.slice(0)
        lastActions.splice(1)

        return (
            <Col className="mb-3">
                <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                        <span>
                            <i className="mdi mdi-cogs mr-2"></i>
                            Actions
                        </span>
                    </CardHeader>
                    { actions.length > 0 ? (
                        <ListGroup flush>
                            { lastActions.map((action: Action, index: number) => (
                                <GuestShowActionsItem key={index} action={action} guest={guest} />
                            ))}
                            { actions.length > lastActions.length && (
                                <ListGroupItem action onClick={this.toggle}>
                                    ... et {actions.length - lastActions.length} autre(s)
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    ) : (
                        <CardBody>Aucune</CardBody>
                    )}
                </Card>
                { actions.length > lastActions.length && (
                    <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>
                            <i className="mdi mdi-cogs mr-2"></i>
                            Actions
                        </ModalHeader>
                        <ModalBody>
                            <ListGroup flush>
                                { actions.map((action: Action, index: number) => (
                                    <GuestShowActionsItem key={index} action={action} guest={guest} />
                                ))}
                            </ListGroup>
                        </ModalBody>
                    </Modal>
                )}
            </Col>
        )
    }

    filterAndOrderedActions (actions: Action[]): Action[] {
        const _actions = actions.slice(0)

        _actions.sort((a: Action, b: Action) => {
            if (a.at == b.at) {
                return 0
            }

            return a.at > b.at ? -1 : 1
        })

        return _actions
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
}
