import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { dateFormat } from '../components/date-formatter'
import {
    Form, FormGroup, Input, Button
} from 'reactstrap'
import { GuestStore } from './store'
import { Guest, Action } from './guest'
import { NotificationStore } from '../notification'
import cn from 'classnames'

interface Props {
    guestStore?: GuestStore;
    notificationStore?: NotificationStore;
    guest: Guest;
    inputRef: React.RefObject<HTMLInputElement>;
}

interface State {
    content: string;
    empty: boolean;
}

@inject('guestStore', 'notificationStore')
@observer
export default class GuestShowMessageCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            content: '',
            empty: false,
        }
    }

    render() {
        const { guest, guestStore, inputRef } = this.props
        const { content, empty } = this.state

        if (!guestStore) {
            return null
        }

        return (
            <Form inline onSubmit={this.submitHandler}>
                <FormGroup className="flex-fill">
                    <input
                        className={cn('form-control', 'flex-fill', { 'is-invalid': empty})}
                        placeholder="Nouveau message. Pas de flood !"
                        value={content}
                        onChange={this.onChangeHandler}
                        ref={inputRef}
                        />
                </FormGroup>
                <Button
                    color="primary"
                    className="ml-5"
                    >
                    <i className="mdi mdi-send mr-2"></i>
                    Envoyer
                </Button>
            </Form>
        )
    }

    onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            content: event.target.value
        })
    }

    submitHandler = (event: React.FormEvent) => {
        event.preventDefault()
        const s = this.state

        if (!s.content) {
            this.setState({
                empty: true
            })

            return
        }

        if (!this.props.guestStore) {
            return
        }

        const res = this.props.guestStore.writeMessage(this.props.guest, s.content)

        this.setState({
            content: '',
            empty: false
        })

        if (this.props.notificationStore) {
            if (res) {
                this.props.notificationStore.addNotificationSuccess(`Votre message a bien été envoyé`)
                if (this.props.inputRef.current) {
                    this.props.inputRef.current.blur()
                }
            } else {
                this.props.notificationStore.addNotificationWarning(`Impossible de joindre le server, votre message sera envoyé dès que possible`)
            }
        }
    }
}
