import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '../components/link'
import { dateFormat } from '../components/date-formatter'
import {
    Badge
} from 'reactstrap'
import { GuestStore } from '../guest'
import { SecurityStore } from '../security'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
}


@inject('guestStore', 'security')
@observer
export default class MessageFooter extends React.Component<Props, {}> {
    render() {

        const { security, guestStore } = this.props

        if (!security || !security.currentUser || !guestStore) {
            return null
        }

        const by = security.currentUser.login

        let nbUnread = 0
        for (const guest of guestStore.guests.slice(0)) {
            if (guest.messages.length > 0) {

                for (const message of guest.messages) {
                    if (!guest.isMessageReadBy(message, by)) {
                        nbUnread ++
                    }
                }
            }
        }

        return (
            <Link
                access='guest.messageRead'
                to='/message'
                nav={true}
                >
                <i className="mdi mdi-email mr-2"></i>
                Messages
                {nbUnread > 0 && (
                    <Badge className="ml-2">
                        {nbUnread}
                    </Badge>
                )}
            </Link>
        )
    }
}
