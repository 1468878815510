import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Card, CardHeader,
    Col,
    CustomInput,
    ListGroup, ListGroupItem
} from 'reactstrap'
import { GuestStore } from './store'
import { SecurityStore } from '../security'
import { Guest, Action } from './guest'
import cn from 'classnames'
import { translateTravelType } from '../util/travel'
import { getDay } from '../util/days'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
    guest: Guest;
}

@inject('security', 'guestStore')
@observer
export default class GuestShowManagement extends React.Component<Props, {}> {
    render() {
        const { guest, guestStore, security } = this.props

        if (!security || !guestStore) {
            return null
        }

        const travelGranted = security.isGranted('guest.data.travel')
        const arrivalManagedGranted = security.isGranted('guest.arrivalManaged')
        const departureManagedGranted = security.isGranted('guest.departureManaged')

        if (!travelGranted || !guest.data.travel) {
            return null
        }

        return (
            <Col className="mb-3">
                <Card>
                    <CardHeader>
                        <i className="mdi mdi-train mr-2"></i>
                        <i className="mdi mdi-airplane mr-2"></i>
                        <i className="mdi mdi-car mr-2"></i>
                        <span>Voyage</span>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="d-flex">
                            Arrivée gérée par la team invité :
                            { arrivalManagedGranted ? (
                                <CustomInput
                                    id={'arrival_managed' + guest.id}
                                    type="switch"
                                    checked={guest.isArrivalManaged}
                                    onChange={this.onArrivalChangeHandler}
                                    className="ml-5"
                                    />
                            ) : (
                                <span className={guest.isArrivalManaged ? 'text-success' : 'text-warning'}>
                                    <i className={guest.isArrivalManaged ? 'mdi mdi-check' : 'mdi mdi-close'}></i>
                                </span>
                            )}
                        </ListGroupItem>
                        <ListGroupItem>
                            Moyen de transport pour l'arrivée :
                            <span
                                className={cn('ml-2', {
                                    'text-info': guest.data.travel.arrival.type === 'independent',
                                    'text-success': guest.data.travel.arrival.type === 'train',
                                    'text-danger': guest.data.travel.arrival.type != 'train' && guest.data.travel.arrival.type != 'independent'
                                })}
                            >
                                { translateTravelType(guest.data.travel.arrival.type) }
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex justify-content-between">
                            <div>
                                Arrivée : { guest.data.travel.arrival.day ? getDay(guest.data.travel.arrival.day) : '' } { guest.data.travel.arrival.end }
                            </div>
                            { guest.data.travel.arrival.to != '' && (
                                <span>Lieu : { guest.data.travel.arrival.to }</span>
                            )}
                            { guest.data.travel.arrival.start != '' && (
                                <span className="text-muted">Provenance : { guest.data.travel.arrival.from } ({guest.data.travel.arrival.start })</span>
                            )}
                        </ListGroupItem>
                        <ListGroupItem className="d-flex">
                            Départ géré par la team invité :
                            { departureManagedGranted ? (
                                <CustomInput
                                    id={'departure_managed' + guest.id}
                                    type="switch"
                                    checked={guest.isDepartureManaged}
                                    onChange={this.onDepartureChangeHandler}
                                    className="ml-5"
                                    />
                            ) : (
                                <span className={guest.isDepartureManaged ? 'text-success' : 'text-warning'}>
                                    <i className={guest.isDepartureManaged ? 'mdi mdi-check' : 'mdi mdi-close'}></i>
                                </span>
                            )}
                        </ListGroupItem>
                        <ListGroupItem>
                            Moyen de transport pour le départ :
                            <span
                                className={cn('ml-2', {
                                    'text-info': guest.data.travel.departure.type === 'independent',
                                    'text-success': guest.data.travel.departure.type === 'train',
                                    'text-danger': guest.data.travel.departure.type != 'train' && guest.data.travel.departure.type != 'independent'
                                })}
                            >
                                { translateTravelType(guest.data.travel.departure.type) }
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex justify-content-between">
                            <div>
                                Départ : { guest.data.travel.departure.day ? getDay(guest.data.travel.departure.day) : '' } { guest.data.travel.departure.start }
                            </div>
                            { guest.data.travel.departure.from != '' && (
                                <span>Lieu : { guest.data.travel.departure.from }</span>
                            )}
                            { guest.data.travel.departure.end != '' && (
                                <span className="text-muted ml-5">Destination : { guest.data.travel.departure.to } ({guest.data.travel.departure.end })</span>
                            )}
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        )
    }

    onArrivalChangeHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.markArrivalManaged(this.props.guest, !this.props.guest.isArrivalManaged)
        }
    }

    onDepartureChangeHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.markDepartureManaged(this.props.guest, !this.props.guest.isDepartureManaged)
        }
    }
}
