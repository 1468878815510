import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { DateFormatter, dateFormat } from '../components/date-formatter'
import { generateTextAction } from '../util/actions'
import {

} from 'reactstrap'
import { ImportantEvent } from './important-event'
import { ImportantEventStore } from './store'
import { GuestStore, StateKey } from '../guest'
import cn from 'classnames'

interface Props {
    importantEventStore?: ImportantEventStore;
    guestStore?: GuestStore;
    event: ImportantEvent;
}

@inject('importantEventStore', 'guestStore')
@observer
export class ImportantEventListItem extends React.Component<Props, {}> {
    render() {
        const { event, importantEventStore } = this.props

        let textCreated = dateFormat(event.at)
        let textSent = 'Non envoyée'
        if (event.sendAt) {
            textSent = dateFormat(event.sendAt)
        }

        if (importantEventStore && !importantEventStore.filteredForUser) {
            textCreated += ' par '
                + event.by

            if (event.sendAt) {
                textSent += ' par '
                + event.sendBy
            }
        }

        const textEvent = this.generateTextEvent(event)

        return (
            <tr>
                <td>
                    { textEvent }
                </td>
                <td>
                    {textCreated}
                </td>
                <td className={cn({'text-warning': !event.sendAt})}>
                    {textSent}
                </td>
                <td>
                {event.sendAt ? (
                    <span className="text-success">
                        <i className="mdi mdi-check" />
                    </span>
                ) : (
                    <span className="text-warning">
                        <i className="mdi mdi-close" />
                    </span>
                )}
                </td>
            </tr>
        )
    }

    generateTextEvent (event: ImportantEvent): string {
        if (!this.props.guestStore) {
            return ''
        }

        const guest = this.props.guestStore.findGuestById(event.args[0])
        return generateTextAction({
            type: (event.event ? event.event.replace('guest.', '') : '') as StateKey,
            data: {
                value: event.args[1],
            },
            by: event.by,
            at: new Date(event.at)
        })
    }
}
