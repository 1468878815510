import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Link } from '../components'
import { departureDays } from '../util/days'
import {
    CustomInput, Label,
    Nav, NavItem
} from 'reactstrap'
import { Navigator } from '../navigation'
import { DepartureListTab } from './departure-list-tab'
import { SecurityStore } from '../security'
import { GuestStore } from '../guest'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps<{day: string}> {
    navigator?: Navigator;
    security?: SecurityStore;
    guestStore?: GuestStore;
}

@inject('security', 'navigator', 'guestStore')
@observer
class DepartureList extends React.Component<Props, {}> {
    constructor (props: Props) {
        super(props)

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'ArrowLeft':
        case 'ArrowRight':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let selectedIndex: number = departureDays.indexOf(this.props.match.params.day)

        switch(code) {
        case 'ArrowLeft':
            if (selectedIndex < 0) {
                selectedIndex = 0
            } else {
                selectedIndex--
            }
            selectedIndex = Math.max(0, selectedIndex)
            this.props.navigator.redirectTo('/departure/' + departureDays[selectedIndex])
            break
        case 'ArrowRight':
            if (selectedIndex < 0) {
                selectedIndex = departureDays.length - 1
            } else {
                selectedIndex++
            }
            selectedIndex = Math.min(departureDays.length - 1, selectedIndex)
            this.props.navigator.redirectTo('/departure/' + departureDays[selectedIndex])
            break
        }
    }

    render() {
        const { security, guestStore } = this.props

        if (!security || !guestStore) {
            return null
        }

        const travelGranted = security.isGranted('guest.data.travel')
        const identityGranted = security.isGranted('guest.data.identity')
        const departureManagedGranted = security.isGranted('guest.departureManaged')
        const receptionGranted = security.isGranted('guest.receptionManaged')
        const managementGranted = security.isGranted('guest.data.management')
        const guestShowGranted = security.isGranted('guest.show')

        if (!travelGranted) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <>
                <Nav pills fill className="my-4">
                    { departureDays.map((day) => (
                        <Link
                            key={'/departure/'+day}
                            to={'/departure/'+day}
                            exact
                            nav={true}
                            >
                            { day }
                        </Link>
                    ))}
                </Nav>
                <div className="d-flex justify-content-around">
                    <div className="d-flex">
                        <Label for={'showManagedDepartures'} className="mr-2">
                            Voir les départs déjà gérés
                        </Label>
                        <CustomInput
                            id={'showManagedDepartures'}
                            type="switch"
                            checked={guestStore.showManagedDepartures}
                            onChange={this.onShowManagedDeparturesHandler}
                            />
                    </div>
                    <div className="d-flex">
                        <Label for={'showIndependentDepartures'} className="mr-2">
                            Voir les départs "autonome"
                        </Label>
                        <CustomInput
                            id={'showIndependentDepartures'}
                            type="switch"
                            checked={guestStore.showIndependentDepartures}
                            onChange={this.onShowIndependentDeparturesHandler}
                            />
                    </div>
                </div>
                <Switch>
                    { departureDays.map((day) => (
                        <Route
                            key={'/departure/'+day}
                            path={'/departure/'+day}
                            render={() => (
                                <DepartureListTab
                                    day={day}
                                    identityGranted={identityGranted}
                                    departureManagedGranted={departureManagedGranted}
                                    receptionGranted={receptionGranted}
                                    managementGranted={managementGranted}
                                    guestShowGranted={guestShowGranted}
                                    />
                            )}
                        />
                    ))}
                </Switch>
            </>
        )
    }

    onShowManagedDeparturesHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.setShowManagedDepartures(!this.props.guestStore.showManagedDepartures)
        }
    }

    onShowIndependentDeparturesHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.setShowIndependentDepartures(!this.props.guestStore.showIndependentDepartures)
        }
    }
}

export default withRouter(DepartureList)
