import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '../components/link'
import { getDay, departureDays } from '../util/days'
import { dateFormat } from '../components/date-formatter'
import {
    Badge
} from 'reactstrap'
import { GuestStore } from '../guest'

interface Props {
    guestStore?: GuestStore;
}

@inject('guestStore')
@observer
export default class DepartureFooter extends React.Component<Props, {}> {
    render() {
        const { guestStore } = this.props

        if (!guestStore) {
            return null
        }

        const nbNotifications = 0 //guestStore.XXX

        let day = getDay('today')
        if (!day || departureDays.indexOf(day) < 0) {
            day = departureDays[0]
        }

        return (
            <Link
                access='guest.data.travel'
                to={'/departure/'+day}
                nav={true}
                >
                <i className="mdi mdi-train mr-2"></i>
                Départs
                {nbNotifications > 0 && (
                    <Badge className="ml-2">
                        {nbNotifications}
                    </Badge>
                )}
            </Link>
        )
    }
}
