import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Card, CardHeader,
    Col,
    ListGroup, ListGroupItem
} from 'reactstrap'
import { SecurityStore } from '../security'
import { Guest } from './guest'
import { festivalDays, getDay } from '../util/days'
import Null from '../util/null'

interface Props {
    security?: SecurityStore;
    guest: Guest;
}

@inject('security')
@observer
export default class GuestShowReception extends React.Component<Props, {}> {
    render() {
        const { guest, security } = this.props

        if (!security) {
            return null
        }

        const receptionGranted = security.isGranted('guest.data.reception')

        if (!receptionGranted || !guest.data.reception) {
            return null
        }

        return (
            <Col className="mb-3">
                <Card>
                    <CardHeader>
                        <i className="mdi mdi-home mr-2"></i>
                        <i className="mdi mdi-beer mr-2"></i>
                        <i className="mdi mdi-pizza mr-2"></i>
                        <span>Hébergement et repas</span>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem>
                            Hotel : { guest.data.reception.lodging ? guest.data.reception.lodging : 'non' }
                        </ListGroupItem>
                        { guest.data.reception.lodging != '' && (
                            <ListGroupItem className="d-flex justify-content-between">
                                <span>Nombre de nuits : { guest.data.reception.nbNights }</span>
                                { guest.data.reception.note && (
                                    <span className="text-muted">Note : { guest.data.reception.note }</span>
                                )}
                            </ListGroupItem>
                        )}
                        { festivalDays.map((day: string, index: number) => (
                            <Null key={index}>
                                <ListGroupItem className="d-flex justify-content-between">
                                    <span>Catering { getDay(day) } : { guest.data.reception.catering && guest.data.reception.catering[day] !== undefined && guest.data.reception.catering[day] >= 0 ? 'oui' : 'non' }</span>
                                    { guest.data.reception.catering && guest.data.reception.catering[day] !== undefined && guest.data.reception.catering[day] >= 0 && (
                                        <span className="text-muted">Nb: { guest.data.reception.catering[day] }</span>
                                    )}
                                </ListGroupItem>
                                <ListGroupItem className="d-flex justify-content-between">
                                    <span>Dîner { getDay(day) } : { guest.data.reception.dinner && guest.data.reception.dinner[day] !== undefined && guest.data.reception.dinner[day] >= 0 ? 'oui' : 'non' }</span>
                                    { guest.data.reception.dinner && guest.data.reception.dinner[day] !== undefined && guest.data.reception.dinner[day] >= 0 && (
                                        <span className="text-muted">Nb: { guest.data.reception.dinner[day] }</span>
                                    )}
                                </ListGroupItem>
                            </Null>
                        ))}
                    </ListGroup>
                </Card>
            </Col>
        )
    }
}
