import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import {
    Button,
    CustomInput
} from 'reactstrap'
import { SecuredView } from '../components/secured-view'
import { GuestStore, Guest } from '../guest'
import { SecurityStore } from '../security'
import DepartureListItemTo from './departure-list-item-to'
import cn from 'classnames'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
    guest: Guest;
    identityGranted: boolean;
    departureManagedGranted: boolean;
    receptionGranted: boolean;
    managementGranted: boolean;
    guestShowGranted: boolean;
    showDay: boolean;
    selected: boolean;
}

@inject('guestStore', 'security')
@observer
export class DepartureListItem extends React.Component<Props, {}> {
    render() {
        const { guestStore, security, guest, identityGranted, departureManagedGranted, receptionGranted, managementGranted, guestShowGranted, showDay, selected } = this.props

        if (!guestStore || !security) {
            return null
        }

        const nbUnreadMessages = security.currentUser ? guest.getNbUnreadMessageBy(security.currentUser.login) : 0

        return (
            <tr className={cn({'text-muted': guest.isDepartureManaged, 'font-weight-bold': selected, 'table-active': selected})}>
                <td>
                    <DepartureListItemTo guest={guest} showDay={showDay} />
                </td>
                <td>
                    { identityGranted ? (guest.data.identity.firstname + ' ' + guest.data.identity.lastname).trim() : ''} {managementGranted && guest.data.management.nbExtraPersons > 0 && (<span className="text-danger">(+{guest.data.management.nbExtraPersons})</span>)}
                </td>
                <td>
                    { identityGranted ? guest.data.identity.stageName : guest.name }
                </td>
                <td>
                    { identityGranted ? guest.data.identity.group : ''}
                </td>
                { receptionGranted && guest.data.reception && (
                    <td>
                        <span>
                            { guest.data.reception.lodging }
                        </span>
                    </td>
                )}

                <td>
                    <div className="d-flex justify-content-end">
                        { departureManagedGranted ? (
                            <CustomInput
                                id={'departure_' + guest.id}
                                type="switch"
                                checked={guest.isDepartureManaged}
                                onChange={this.onDepartureChangeHandler}
                                />
                        ) : (
                            <span className={guest.isDepartureManaged ? 'text-success' : 'text-warning'}>
                                <i className={guest.isDepartureManaged ? 'mdi mdi-check' : 'mdi mdi-close'}></i>
                            </span>
                        )}
                        <Link
                            to={'/guest/'+guest.id}
                            className={cn('btn btn-secondary btn-sm ml-5', {'btn-warning': nbUnreadMessages > 0, 'disabled': !guestShowGranted})}
                            >
                            <i className="mdi mdi-eye"></i>
                        </Link>
                    </div>
                </td>
            </tr>
        )
    }

    onDepartureChangeHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.markDepartureManaged(this.props.guest, !this.props.guest.isDepartureManaged)
        }
    }
}
