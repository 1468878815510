import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Button,
    Card, CardTitle,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { SecurityStore } from '../security'
import { GuestStore } from './store'
import { Guest } from './guest'
import cn from 'classnames'

interface Props {
    security?: SecurityStore;
    guestStore?: GuestStore;
    guest: Guest;
}

interface State {
    updatePictureModal: boolean
    pictureTmp: string
}

@inject('security', 'guestStore')
@observer
export default class GuestShowIdentity extends React.Component<Props, State> {

    protected pictureInput: React.RefObject<HTMLInputElement>

    constructor (props: Props) {
        super(props)

        this.pictureInput = React.createRef<HTMLInputElement>()

        this.state = {
            updatePictureModal: false,
            pictureTmp: ''
        }
    }

    render() {
        const { guest, security } = this.props
        if (!security) {
            return null
        }

        const identityGranted = security.isGranted('guest.data.identity')

        if (!identityGranted) {
            return null
        }

        const updatePictureGranted = security.isGranted('guest.updatePicture')
        console.log(guest.data)

        return (
            <Col className="mb-3">
                <Card body>
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <h2>{ guest.data.identity.stageName }</h2>
                            <h3 className="text-muted">{ (guest.data.identity.firstname + ' ' + guest.data.identity.lastname).trim() }</h3>
                            { guest.data.identity.denomination != '' && (
                                <h3>{ guest.data.identity.denomination }</h3>
                            )}
                            { guest.data.identity.group && (
                                <h4>Groupe : { guest.data.identity.group }</h4>
                            )}
                            { guest.data.identity.notes && (
                                <p>Notes : { guest.data.identity.notes }</p>
                            )}
                        </div>
                        <div className="position-relative">
                            { guest.picture  && (
                                <img src={`${guest.picture}`}
                                    style={{
                                        height: 200,
                                    }}
                                    />
                            )}
                            { updatePictureGranted && (
                                <>
                                    <Button
                                        color="primary"
                                        outline={true}
                                        className="position-absolute border-0"
                                        style={{ right: 0 }}
                                        onClick={this.toggleUpdatePictureModal}
                                        >
                                        <i className="mdi mdi-camera mdi-24px"></i>
                                    </Button>
                                    <Modal
                                        isOpen={this.state.updatePictureModal}
                                        toggle={this.toggleUpdatePictureModal}
                                        onOpened={this.focusOnPictureInput}
                                        >
                                        <form
                                            onSubmit={this.submitForm}
                                            >
                                            <ModalHeader toggle={this.toggleUpdatePictureModal}>
                                                <i className="mdi mdi-alert mr-2"></i>
                                                Modifier la photo de l'invité
                                            </ModalHeader>
                                            <ModalBody className="text-center">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={this.onChangePictureHandler}
                                                    value={this.state.pictureTmp}
                                                    ref={this.pictureInput}
                                                    />
                                                    <img src={`${this.state.pictureTmp}`}
                                                    style={{
                                                        height: 200,
                                                    }}
                                                    className="m-2"
                                                />
                                            </ModalBody>
                                            <ModalFooter className="d-flex justify-content-between">
                                                <Button
                                                    color="info"
                                                    onClick={this.toggleUpdatePictureModal}
                                                    >
                                                    Annuler
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    onClick={this.updatePicture}
                                                    >
                                                    Valider
                                                </Button>
                                            </ModalFooter>
                                        </form>
                                    </Modal>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={cn('d-flex flex-row justify-content-around', { 'text-muted': guest.isPresent, 'text-primary': !guest.isPresent })}>
                        { guest.data.management.badge && (
                            <div>
                                <i className="mdi mdi-badge-account-horizontal-outline mdi-48px"></i>
                            </div>
                        )}
                        <div>
                            <i className="mdi mdi-hand-back-left mdi-48px"></i>
                            { guest.data.management.nbExtraPersons > 0 && (
                                <>
                                    x
                                    { parseInt(guest.data.management.nbExtraPersons, 10) + 1 }
                                </>
                            )}
                        </div>
                        { guest.data.reception && guest.data.reception.catering && guest.data.reception.catering.fri && (
                            <div>
                                <div className="position-relative d-inline-block">
                                    <i className="mdi mdi-ticket-outline mdi-48px"></i>
                                    <i className="mdi mdi-alpha-v-circle-outline mdi-24px" style={{ position: 'absolute', top: 18, left: 12 }}></i>
                                </div>
                                { parseInt(guest.data.reception.catering.fri) > 1 ? 'x' + guest.data.reception.catering.fri : '' }
                            </div>
                        )}
                        { guest.data.reception && guest.data.reception.catering && guest.data.reception.catering.sat && (
                            <div>
                                <div className="position-relative d-inline-block">
                                    <i className="mdi mdi-ticket-outline mdi-48px"></i>
                                    <i className="mdi mdi-alpha-s-circle-outline mdi-24px" style={{ position: 'absolute', top: 18, left: 12 }}></i>
                                </div>
                                { parseInt(guest.data.reception.catering.sat) > 1 ? 'x' + guest.data.reception.catering.sat : '' }
                            </div>
                        )}
                        { guest.data.reception && guest.data.reception.catering && guest.data.reception.catering.sun && (
                            <div>
                                <div className="position-relative d-inline-block">
                                    <i className="mdi mdi-ticket-outline mdi-48px"></i>
                                    <i className="mdi mdi-alpha-d-circle-outline mdi-24px" style={{ position: 'absolute', top: 18, left: 12 }}></i>
                                </div>
                                { parseInt(guest.data.reception.catering.sun) > 1 ? 'x' + guest.data.reception.catering.sun : '' }
                            </div>
                        )}
                    </div>
                </Card>
            </Col>
        )
    }

    focusOnPictureInput = () => {
        if (this.pictureInput.current) {
            this.pictureInput.current.focus()
            this.pictureInput.current.select()
        }
    }

    protected toggleUpdatePictureModal = () => {
        this.setState({
            updatePictureModal: !this.state.updatePictureModal,
            pictureTmp: this.props.guest.picture
        })

        if (this.pictureInput.current) {
            this.pictureInput.current.focus()
        }
    }

    protected onChangePictureHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            pictureTmp: event.target.value
        })

        event.stopPropagation()
    }

    protected submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        this.updatePicture()
    }

    protected updatePicture = () => {

        const { security } = this.props

        if (!security) {
            return
        }

        const updatePictureGranted = security.isGranted('guest.updatePicture')

        if (!updatePictureGranted) {
            return
        }

        if (this.props.guestStore) {
            this.props.guestStore.upadetPicture(this.props.guest, this.state.pictureTmp)
        }

        this.setState({
            updatePictureModal: false,
            pictureTmp: ''
        })
    }
}
