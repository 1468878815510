import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { CommunicationStore } from './store'
import cn from 'classnames'

interface Props {
    communication?: CommunicationStore;
}

@inject('communication')
@observer
export class CommunicationActions extends React.Component<Props, {}> {
    render() {
        const { communication } = this.props

        if (!communication) {
            return null
        }

        return (
            <div className="d-flex">
                {communication.isConnected ? (
                    <i className="mdi mdi-lan-connect"></i>
                ) : (
                    <i className="mdi mdi-lan-disconnect"></i>
                )}
                <div className={cn('ml-2', { 'text-warning': !communication.isConnected})}>
                    { communication.isConnected ? 'Connecté au serveur' : 'Serveur non joignable'}
                </div>
            </div>
        )
    }
}
