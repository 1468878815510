import * as React from 'react'
import { Spinner } from 'reactstrap'
import AuthLayout from './layout/auth'
import { Navigator, Redirector } from './navigation'
import ConnectedLayout from './layout/connected'
import { NotificationStore, Notificator } from './notification'
import { SecurityStore } from './security'
import { CommunicationStore, Connection } from './communication'
import { GuestStore } from './guest/store'
import { ImportantEventStore } from './important-event/store'
import { configure } from 'mobx'
import { observer, Provider } from 'mobx-react'
import { Helmet } from 'react-helmet'

interface Props {
    apiEndpoint: string;
    apiPublicKey: string;
    appTitle: string
}
interface State {}

configure({ enforceActions: 'observed' })

@observer
export default class Bootstrap extends React.PureComponent<Props, State> {
    protected navigator: Navigator
    protected connection: Connection
    protected notificationStore: NotificationStore
    protected securityStore: SecurityStore
    protected communicationStore: CommunicationStore
    protected guestStore: GuestStore
    protected importantEventStore: ImportantEventStore

    constructor (props: Props) {
        super(props)

        this.navigator = new Navigator()

        this.connection = new Connection(this.props.apiEndpoint)
        this.notificationStore = new NotificationStore(this.connection)
        this.securityStore = new SecurityStore(this.connection, this.props.apiPublicKey, this.notificationStore)
        this.communicationStore = new CommunicationStore(this.connection)
        this.guestStore = new GuestStore(this.connection, this.securityStore, this.navigator)
        this.importantEventStore = new ImportantEventStore(this.connection, this.securityStore)
    }

    render () {
        const stores = {
            navigator: this.navigator,
            security: this.securityStore,
            notificationStore: this.notificationStore,
            communication: this.communicationStore,
            guestStore: this.guestStore,
            importantEventStore: this.importantEventStore,
        }

        return (
            <Provider {...stores}>
                <Helmet>
                    <title>{ this.props.appTitle }</title>
                </Helmet>
                <Redirector />
                <Notificator />
                    {this.securityStore.isAuthenticated ? (
                        <ConnectedLayout title={ this.props.appTitle } />
                    ) : (
                        <AuthLayout title={ this.props.appTitle } />
                    )}
            </Provider>
        )
    }
}