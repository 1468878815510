import * as React from 'react';
import {
    Container,
    Collapse,
    Navbar, NavbarBrand, NavbarToggler, Nav, NavItem
} from 'reactstrap'
import { observer, inject } from 'mobx-react'
import { Route, Switch, NavLink } from 'react-router-dom'
import { Link, TitleItem, SecuredRoute } from '../components'
import { Navigator } from '../navigation'
import { GuestScreen } from '../guest'
import { SecurityStore, SecurityActions } from '../security'
import { UserScreen } from '../user'
import { CommunicationStore, CommunicationActions } from '../communication'
import { ImportantEventList } from '../important-event'
import { SystemFooter } from '../system'
import { ArrivalList, ArrivalFooter, DepartureList, DepartureFooter } from '../travel'
import { MessageList, MessageFooter } from '../message'
import { Home  } from '../screen'
import { getDay, arrivalDays, departureDays } from '../util/days'
import { dateFormat } from '../components/date-formatter'
import { MobileOnlyView, BrowserView, TabletView } from 'react-device-detect'

interface Props {
    navigator?: Navigator;
    security?: SecurityStore;
    communication?: CommunicationStore;
    title: string;
}

interface State {
    isOpen: boolean;
}

@inject('security', 'communication', 'navigator')
@observer
export default class ConnectedLayout extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props)

        this.state = {
            isOpen: false
        }

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'Escape':
        case 'F1':
        case 'F2':
        case 'F3':
        case 'F4':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string, event: KeyboardEvent) => {
        if (!this.props.navigator) {
            return
        }

        let day: string | undefined = ''
        switch(code) {
        case 'Escape':
            if (event.ctrlKey) {
                if (this.props.security) {
                    this.props.security.disconnect()
                }
                return
            }

            this.props.navigator.redirectTo('/')
            break
        case 'F1':
            this.props.navigator.redirectTo('/guest')
            break
        case 'F2':
            day = getDay('today')
            if (!day || arrivalDays.indexOf(day) < 0) {
                day = arrivalDays[0]
            }
            this.props.navigator.redirectTo('/arrival/' + day)
            break
        case 'F3':
            day = getDay('today')
            if (!day || departureDays.indexOf(day) < 0) {
                day = departureDays[0]
            }
            this.props.navigator.redirectTo('/departure/' + day)
            break
        case 'F4':
            this.props.navigator.redirectTo('/message')
            break
        }
    }

    render() {
        const { security, communication } = this.props

        if (!security || !communication) {
            return null
        }

        return (
            <>
                <Navbar color="primary" dark expand="md">
                    <Link
                        className="navbar-brand"
                        to="/"
                        >
                        <MobileOnlyView>
                            JTF2020 - GM
                        </MobileOnlyView>
                        <BrowserView>
                            { this.props.title }
                        </BrowserView>
                        <TabletView>
                            { this.props.title }
                        </TabletView>

                    </Link>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <Link
                                access="guest"
                                to="/guest"
                                nav={true}
                                >
                                <i className="mdi mdi-account mr-2"></i>
                                Invités
                            </Link>
                            <ArrivalFooter />
                            <DepartureFooter />
                            <MessageFooter />
                            <SystemFooter />
                        </Nav>
                        <Nav navbar>
                            <SecurityActions />
                        </Nav>
                    </Collapse>
                </Navbar>
                <Container fluid>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <SecuredRoute access="guest" path="/guest" component={GuestScreen}/>
                        <SecuredRoute access="guest.data.travel" path="/arrival/:day" component={ArrivalList}/>
                        <SecuredRoute access="guest.data.travel" path="/departure/:day" component={DepartureList}/>
                        <SecuredRoute access="guest.messageRead" path="/message" component={MessageList}/>
                        <Route path="/system/important-event" component={ImportantEventList}/>
                        {communication.isConnected && (
                            <SecuredRoute access="user" path="/system/user" component={UserScreen}/>
                        )}
                    </Switch>
                </Container>
            </>
        )
    }

    toggle = () => {
        this.setState({
            isOpen : !this.state.isOpen
        })
    }
}
