import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import GuestList from './list'
import GuestShow from './show'

export default class GuestScreen extends React.Component {
    render() {

        return (
            <Switch>
                <Route exact path="/guest/:id" component={GuestShow} />
                <Route path="/guest" component={GuestList} />
            </Switch>
        )
    }
}
