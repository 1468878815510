import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, Redirect } from 'react-router-dom'
import {
    Table
} from 'reactstrap'
import { Navigator } from '../navigation'
import MessageListItem from './list-item'
import { GuestStore, Guest, Action } from '../guest'
import { SecurityStore } from '../security'

interface Props {
    navigator?: Navigator;
    guestStore?: GuestStore;
    security?: SecurityStore;
}


@inject('guestStore', 'security', 'navigator')
@observer
export default class MessageList extends React.Component<Props, {}> {
    constructor (props: Props) {
        super(props)

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let max: number
        let selectedIndex: number

        switch(code) {
        case 'Enter':
            if (this.props.guestStore) {
                const guests = this.filterAndOrdered(this.props.guestStore.guests)

                if (this.props.guestStore.selectedIndex !== null && this.props.guestStore.selectedIndex >= 0 && this.props.guestStore.selectedIndex < guests.length) {
                    this.props.navigator.redirectTo('/guest/' + guests[this.props.guestStore.selectedIndex].id)
                    break
                }

                if (guests.length === 1) {
                    this.props.navigator.redirectTo('/guest/' + guests[0].id)
                }
            }
            break
        case 'Home':
            if (this.props.guestStore) {
                this.props.guestStore.setSelectedIndex(0)
            }
            break
        case 'End':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrdered(this.props.guestStore.guests).length - 1
                this.props.guestStore.setSelectedIndex(max)
            }
            break
        case 'ArrowUp':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrdered(this.props.guestStore.guests).length - 1
                selectedIndex = Math.max(0, this.props.guestStore.selectedIndex === null ? max : this.props.guestStore.selectedIndex - 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        case 'ArrowDown':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrdered(this.props.guestStore.guests).length - 1
                selectedIndex = Math.min(max, this.props.guestStore.selectedIndex === null ? 0 : this.props.guestStore.selectedIndex + 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        }
    }

    render() {
        const { guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        if (!security.isGranted('guest.messageRead')) {
            return (
                <Redirect to='/' />
            )
        }
        const guests = this.filterAndOrdered(guestStore.guests)

        if (guests.length === 0) {
            return null
        }

        return (
            <Table striped className="mt-4">
                <tbody>
                    { guests.map((guest: MessageListGuest, index: number) => (
                        <MessageListItem key={index} {...guest} selected={guestStore.selectedIndex === index}/>
                    ))}
                </tbody>
            </Table>
        )
    }

    filterAndOrdered (guests: Guest[]): any[] {
        const _guests: MessageListGuest[] = []
        const by = this.props.security && this.props.security.currentUser ? this.props.security.currentUser.login : ''

        for (const guest of guests.slice(0)) {
            if (guest.messages.length > 0) {
                let nbUnread = 0
                let lastAt: Date | null = null

                for (const message of guest.messages) {
                    if (!guest.isMessageReadBy(message, by)) {
                        nbUnread ++
                    }
                    if (!lastAt || lastAt < message.at) {
                        lastAt = message.at
                    }
                }

                _guests.push({
                    id: guest.id,
                    guest: guest,
                    nbUnread: nbUnread,
                    lastAt: lastAt,
                    nbMessages: guest.messages.length,
                })
            }
        }

        _guests.sort((a: MessageListGuest, b: MessageListGuest) => {
            if (a.lastAt === b.lastAt) {
                return 0
            }

            if (a.lastAt === null) {
                return 1
            }

            if (b.lastAt === null) {
                return -1
            }

            return a.lastAt > b.lastAt ? -1 : 1
        })

        return _guests
    }
}

interface MessageListGuest {
    id: number;
    guest: Guest;
    nbUnread: number;
    lastAt: Date | null;
    nbMessages: number;
}
