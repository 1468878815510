import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
    Alert,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row,
    Spinner
} from 'reactstrap'
import { Navigator } from '../navigation'
import GuestShowIdentity from './show_identity'
import GuestShowManagement from './show_management'
import GuestShowMessage from './show_message'
import GuestShowTravel from './show_travel'
import GuestShowReception from './show_reception'
import GuestShowActions from './show_actions'
import { GuestStore } from './store'
import { SecurityStore } from '../security'
import { Guest } from './guest'

interface Props extends RouteComponentProps<any> {
    navigator?: Navigator;
    guestStore?: GuestStore;
    security?: SecurityStore;
}

interface State {
    isOpen: boolean;
}

@inject('guestStore', 'security', 'navigator')
@observer
export default class GuestShow extends React.Component<Props, State> {
    protected messageInput: React.RefObject<HTMLInputElement>
    constructor (props: Props) {
        super(props)

        this.state = {
            isOpen: false
        }

        this.messageInput = React.createRef<HTMLInputElement>()

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'Backspace':
            if (this.messageInput.current && this.messageInput.current !== document.activeElement && this.messageInput.current.value) {
                event.preventDefault()
            }
            break
        case 'F5':
        case 'F6':
        case 'F7':
        case 'F8':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let max: number
        let selectedIndex: number
        let guest: Guest | null
        switch(code) {
        case 'Backspace':
            if (this.messageInput.current !== document.activeElement) {
                this.props.navigator.goBack()
            } else if (this.messageInput.current && !this.messageInput.current.value) {
                this.messageInput.current.blur()
            }
            break
        case 'F5':
            if (this.props.guestStore) {
                guest = this.props.guestStore.findGuestById(this.props.match.params.id)
                if (guest) {
                    this.props.guestStore.markPresent(guest, !guest.isPresent)
                }
            }
            break
        case 'F6':
            if (this.messageInput.current) {
                this.messageInput.current.focus()
            }
            break
        case 'F7':
            if (this.props.guestStore) {
                guest = this.props.guestStore.findGuestById(this.props.match.params.id)
                if (guest) {
                    this.props.guestStore.markArrivalManaged(guest, !guest.isArrivalManaged)
                }
            }
            break
        case 'F8':
            if (this.props.guestStore) {
                guest = this.props.guestStore.findGuestById(this.props.match.params.id)
                if (guest) {
                    this.props.guestStore.markDepartureManaged(guest, !guest.isDepartureManaged)
                }
            }
            break
        }
    }


    render() {
        const { guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        if (!guestStore.ready) {
            return (
                <div className="d-flex justify-content-center m-5 p-5">
                    <Spinner />
                </div>
            )
        }

        const guest = guestStore.findGuestById(this.props.match.params.id)

        if (!guest) {
            return (
                <Redirect to="/guest" />
            )
        }

        const guestShowGranted = security.isGranted('guest.show')
        if (!guestShowGranted) {
            return (
                <Redirect to="/guest" />
            )
        }

        return (
            <>
                { !guest.synchronized && !guest.isInternal && (
                    <Alert color="warning" className="mt-2">
                        Cet invité n'est pas synchronisé !
                    </Alert>
                )}
                <Row className="mt-4 row-cols-2">
                    <GuestShowIdentity guest={guest} />
                    <GuestShowManagement guest={guest} />
                    <GuestShowTravel guest={guest} />
                    <GuestShowReception guest={guest} />
                    <GuestShowMessage guest={guest} inputRef={this.messageInput}/>
                    <GuestShowActions guest={guest} />
                </Row>
                { security.isGranted('guest.remove') && (
                    <div className="d-flex justify-content-end">
                        <Button onClick={this.toggle}>
                            <i className="mdi mdi-trash-can-outline"></i>
                        </Button>
                        <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>
                                <i className="mdi mdi-alert mr-2"></i>
                                Confirmation
                            </ModalHeader>
                            <ModalBody>
                                Etes vous sûr de vouloir supprimer cet invité ?
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-between">
                                <Button
                                    color="info"
                                    onClick={this.toggle}
                                    >
                                    Annuler
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={() => this.confirmRemoveGuest(guest)}
                                    >
                                    Supprimer
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                )}
            </>
        )
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    confirmRemoveGuest (guest: Guest) {
        if (this.props.guestStore) {
            this.props.guestStore.removeGuest(guest)
        }
    }
}
