import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { dateFormat } from '../components/date-formatter'
import {
    Button,
    Card, CardHeader, CardBody,
    Col,
    ListGroup, ListGroupItem
} from 'reactstrap'
import GuestShowMessageCreate from './show_message_create'
import { GuestStore } from './store'
import { SecurityStore } from '../security'
import { Guest, Message } from './guest'
import cn from 'classnames'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
    guest: Guest;
    inputRef: React.RefObject<HTMLInputElement>;
}

@inject('security', 'guestStore')
@observer
export default class GuestShowMessage extends React.Component<Props, {}> {
    render() {
        const { guest, security, inputRef } = this.props

        if (!security) {
            return null
        }

        const messageReadGranted = security.isGranted('guest.messageRead')
        const messageWriteGranted = security.isGranted('guest.messageWrite')

        if (!messageReadGranted) {
            return null
        }

        const messages = this.filterAndOrdered(guest.messages)

        return (
            <Col className="mb-3">
                <Card>
                    <CardHeader>
                        <i className="mdi mdi-email-outline mr-2"></i>
                        Messages
                    </CardHeader>
                    { messageWriteGranted && (
                        <CardBody className="border-bottom">
                            <GuestShowMessageCreate guest={guest} inputRef={inputRef}/>
                        </CardBody>
                    )}
                    <ListGroup flush>
                    { messages.map((message: Message, index: number) => {
                        const isRead = security && security.currentUser ? guest.isMessageReadBy(message, security.currentUser.login) : false
                        return (
                            <ListGroupItem key={index} className="d-flex flex-row justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Button
                                        onClick={this.onReadMessageHandler.bind(this, message, isRead)}
                                        size="sm"
                                        outline={isRead}
                                        >
                                        <i className={ isRead ? 'mdi mdi-email-open-outline' : 'mdi mdi-email-outline' }></i>
                                    </Button>
                                    <div className="d-flex justify-content-center flex-column ml-2">
                                        <span className={!isRead ? 'font-weight-bold' : ''}>Message de "{message.by}"</span>
                                        <span className={!isRead ? 'font-weight-bold' : ''}>{dateFormat(message.at)}</span>
                                    </div>
                                </div>
                                <div className={cn('flex-fill pl-2', { 'font-weight-bold': !isRead })}>
                                    {message.content}
                                </div>
                            </ListGroupItem>
                        )
                    })}
                    </ListGroup>
                    <CardBody className="text-info">
                        On ne communique sur ce canal que les informations relative à l'invité en question (demandes, notes, soucis...)
                    </CardBody>
                </Card>
            </Col>
        )
    }

    filterAndOrdered (messages: Message[]): Message[] {
        const _messages = messages.slice(0)

        _messages.sort((a: Message, b: Message) => {
            if (a.at == b.at) {
                return 0
            }

            return a.at > b.at ? -1 : 1
        })

        return _messages
    }

    onReadMessageHandler (message: Message, isRead: boolean) {
        if (this.props.guestStore && this.props.security && this.props.security.currentUser) {
            this.props.guestStore.readMessage(this.props.guest, this.props.security.currentUser.login, message.uuid, !isRead)
        }
    }
}
