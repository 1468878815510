import { observable, action, computed, reaction } from 'mobx'
import { createHash } from 'crypto'
import { Connection } from '../communication'
import { NotificationStore } from '../notification'
import { User } from './user'
import * as jwt from 'jsonwebtoken'
import Cookies, { CookieSetOptions } from 'universal-cookie'

export class SecurityStore {
    protected connection: Connection
    protected notificationStore: NotificationStore
    @observable users: User[] = []
    @observable managers: string[] = []
    @observable login: string | null = null
    @observable password = ''
    @observable error: string | null = null
    @observable lastLogin: string | null = null

    protected _apiPublicKey: string
    @observable token: string = ''
    @observable informations: Informations = {
        iat: 0,
        id: '',
        admin: false,
        login: '',
        groups: [],
        managers: []
    }
    protected _cookies: Cookies

    constructor (connection: Connection, apiPublicKey: string, notificationStore: NotificationStore) {
        this.connection = connection
        this.notificationStore = notificationStore

        this.addListenerToConnection()

        this._apiPublicKey = apiPublicKey
        this._cookies = new Cookies()

        this.loadTokenFromCookie()
    }

    @computed
    get isAuthenticated () {
        return this.token !== ''
    }

    @computed
    get currentUser (): User | null {
        if (!this.isAuthenticated) {
            return null
        }

        if( (!this.informations.login)) {
            return null
        }

        return {
            id: this.informations.id,
            admin: this.informations.admin,
            login: this.informations.login,
            groups: this.informations.groups.slice(0),
            managers: this.informations.managers.slice(0)
        }
    }

    @action
    setUsers (users: User[]) {
        this.users.splice(0)
        for (const user of users) {
            this.users.push(user)
        }
    }

    addListenerToConnection () {
        this.connection.on('user.list', (users) => {
            this.setUsers(users)
        })

        this.connection.on('user.updated', (id) => {
            if (this.currentUser && this.currentUser.id === id) {
                this.disconnect()
            }
            this.connection.emit('user.list')
        })

        this.connection.on('connect', () => {
            this.loadTokenFromCookie()

            this.connection.emit('user.list')

            if (this.isAuthenticated) {
                this.connection.emit('login.token', this.token)
            } else {
                this.connection.emit('logout')
            }
        })

        this.connection.on('login.success', action((data: {token: string}) => {
            try {
                const decoded = jwt.verify(data.token, this._apiPublicKey)
                if (decoded) {
                    this.token = data.token
                    this.informations = Object.assign(this.informations, decoded)
                    this.saveTokenInCookie()
                } else {
                    // ERROR
                }
            } catch (error) {
                // ERROR
            }
        }))

        this.connection.on('login.error', action((error: string) => {
            switch(error) {
            case 'already_connected':
                break
            case 'not_found':
                this.error = 'Utilisateur inconnu'
                this.setLogin('')
                break
            case 'wrong_password':
                this.error = 'Mauvais mot de passe'
                this.clearPassword()
                break
            default:
                this.error = 'Erreur lors de la connexion'
                break
            }
        }))

        this.connection.on('user.error', (code, login, ...args) => {
            if (code === 'already_exists') {
                this.notificationStore.addNotificationError (`Un utilisateur avec le login "${login}" existe déjà !`)
            }
        })

        this.connection.on('user.created', (login) => {
            this.notificationStore.addNotificationSuccess (`L'utilisateur "${login}" a bien été créé !`)
        })

        this.connection.on('user.set-groups', (login, groups) => {
            this.notificationStore.addNotificationSuccess (`Les droits de l'utilisateur "${login}" ont été modifiés !`)
        })

        this.connection.on('guest.manager.list', action((managers: string[]) => {
            this.managers.splice(0)
            for (const manager of managers) {
                this.managers.push(manager)
            }
        }))

        reaction(
            () => this.isAuthenticated,
            this.refreshGuestManagerList
        )
    }

    refreshGuestManagerList = () => {
        if (this.isAuthenticated) {
            this.connection.emit('guest.manager.list')
        }
    }

    findUserIndexById (id: string): number {
        for (const index in this.users) {
            if (this.users[index].id === id) {
                return parseInt(index, 10)
            }
        }

        return -1
    }

    findUserById (id: string): User | null {
        const index = this.findUserIndexById(id)
        if (index < 0) {
            return null
        }

        return this.users[index]
    }

    findUserByLogin (login: string): User | null {
        for (const user of this.users) {
            if (user.login === login) {
                return user
            }
        }

        return null
    }

    @action
    setLastLogin (lastLogin: string) {
        if (!this.isAuthenticated) {
            this.lastLogin = lastLogin
        }
    }

    @action
    setLogin (login: string, tryToConnect = false) {
        if (!this.isAuthenticated) {
            this.login = login
        }

        if (tryToConnect) {
            this.connect()
        }
    }

    @action
    setPassword (password: string, tryToConnect = false) {
        if (!this.isAuthenticated) {
            this.password = password
        }

        if (tryToConnect) {
            this.connect()
        }
    }

    @action
    appendToPassword (c: string) {
        if (!this.isAuthenticated) {
            this.password += c
        }
    }

    @action
    clearPassword () {
        if (!this.isAuthenticated) {
            this.password = ''
        }
    }

    @action
    connect () {
        if (this.login && this.password) {

            if (this.connection.connected) {
                this.connection.emit('login', this.login, this.password)
            }
        }
    }

    @action
    disconnect () {
        if (!this.isAuthenticated) {
            return
        }

        this.login = ''
        this.password = ''

        this.token = ''
        this.informations = {
            iat: 0,
            id: '',
            admin: false,
            login: '',
            groups: [],
            managers: []
        }
        this.deleteTokenCookie()

        if (this.connection.connected) {
            this.connection.emit('logout')
        }
    }

    @action
    clearError () {
        this.error = null
    }

    createUser (login: string, password: string) {
        this.connection.emit('user.create', login, password)
    }

    removeUser(login: string) {
        this.connection.emit('user.remove', login)
    }

    changeUserPassword (login: string, password: string) {
        if (password) {
            this.connection.emit('user.change-password', login, password)
        }
    }

    changeUserGroups (login: string, groups: string[]) {
        this.connection.emit('user.groups.set', login, groups)
    }

    changeUserManagers (login: string, managers: string[]) {
        this.connection.emit('user.managers.set', login, managers)
    }

    isGranted (access: string) {
        if (!this.isAuthenticated) {
            return false
        }

        if (!this.currentUser) {
            return false
        }

        if (this.currentUser.admin) {
            return true
        }

        // if user got xxx (global access) for access xxx.yyyy => granted
        const r = new RegExp('^([^.]+)\\.(.+)$')
        if (r.test(access)) {
            const matches = r.exec(access)
            if (matches && matches[1] && this.currentUser.groups.indexOf(matches[1]) >= 0) {
                return true
            }
        }

        // if access is global as xxxx and user got xxxx.yyyy => granted
        const r2 = new RegExp('^([^.]+)$')
        if (r2.test(access)) {
            const r3 = new RegExp('^'+access+'\\.(.+)$')
            for (const group of this.currentUser.groups) {
                if (r3.test(group)) {
                    return true
                }
            }
        }

        return this.currentUser.groups.indexOf(access) >= 0
    }

    @action
    protected loadTokenFromCookie () {
        const token = this._cookies.get('api-token')

        if (token) {
            try {
                const decoded = jwt.verify(token, this._apiPublicKey)
                if (decoded) {
                    this.token = token
                    this.informations = Object.assign(this.informations, decoded)

                    if (this.connection.connected) {
                        this.connection.emit('login.token', this.token)
                    }
                }
            } catch (error) {
                // do nothing
            }
        }
    }

    protected saveTokenInCookie (longlife: boolean = false) {
        if (this.token) {
            const options: CookieSetOptions = {
                path: '/'
            }

            if (longlife) {
                options.maxAge = 3600 * 24 * 7
            }

            this._cookies.set('api-token', this.token, options)
        }
    }

    protected deleteTokenCookie () {
        this._cookies.remove('api-token')
    }
}

export interface Informations {
    iat: number;
    id: string;
    admin: boolean;
    login: string;
    groups: string[];
    managers: string[];
}
