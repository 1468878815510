import * as React from 'react'
import { NavLink as BaseLink, NavLinkProps as BaseLinkProps } from 'react-router-dom'
import { SecuredRoute } from './secured-route'
import {
    DropdownItem,
    NavItem
} from 'reactstrap'

interface LinkProps extends BaseLinkProps {
    activable?: boolean;
    selectable?: boolean;
    access?: string;
    nav?: boolean;
    dropdown?: boolean;
}

export const Link = ({ children, to, exact, component, activable, selectable, access, nav, dropdown, ...args }: LinkProps) => (
    <SecuredRoute path={to.toString()} exact={exact} access={access} redirect={false} children={({ match }) => {
        const content =
            <BaseLink
                to={to}
                component={component}
                className={nav ? 'nav-link' : ''}
                //active={(activable && match && (!exact || match.isExact)) ? true : false}
                //selected={(selectable && match && (!exact || match.isExact)) ? true : false}
                {...args}
            >
                {children}
            </BaseLink>

        if (nav) {
            return <NavItem>
                { content }
            </NavItem>
        }

        if (dropdown) {
            return <DropdownItem>
                { content }
            </DropdownItem>
        }

        return content
    }} />
)
