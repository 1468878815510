import { Guest, Action } from '../guest/guest'

export const generateTextAction = (action: Action, guest?: Guest) => {
    const guestName = guest ? guest.name : 'inconnu'
    switch (action.type) {
        case 'departureManaged':
            if (action.data && action.data.value) {
                return `"${action.by}" a déclaré avoir géré le départ de "${guestName}"`
            } else {
                return `"${action.by}" a déclaré que le départ de "${guestName}" n'est pas géré`
            }
        case 'arrivalManaged':
            if (action.data && action.data.value) {
                return `"${action.by}" a déclaré avoir géré l'arrivée de "${guestName}"`
            } else {
                return `"${action.by}" a déclaré que l'arrivée de "${guestName}" n'est pas géré`
            }
        case 'present':
            return `"${action.by}" a déclaré "${guestName}" ${action.data && action.data.value ? 'présent(e)' : 'absent(e)'}`
        case 'messageWrite':
            return `"${action.by}" a envoyé un message à propos de "${guestName}"`
        case 'messageRead':
            return `"${action.by}" a lu un message à propos de "${guestName}"`
        case 'picture':
            return `"${action.by}" a modifié la photo de "${guestName}"`
        default:
            return action.type
    }
}
