import * as React from 'react';
import { inject } from 'mobx-react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Navigator } from '../navigation'

interface Props extends RouteComponentProps<any> {
    navigator?: Navigator;
}

@inject('navigator')
class ConnectedLayout extends React.Component<Props, {}> {
    constructor (props: Props) {
        super(props)

        if (this.props.navigator) {
            this.props.navigator.on('redirect', this.onRedirect)
            this.props.navigator.on('goBack', this.onGoBack)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('redirect', this.onRedirect)
            this.props.navigator.off('goBack', this.onGoBack)
        }
    }

    onRedirect = (path: string, exact: boolean = true) => {
        if (this.props.history) {
            if (
                (!exact && this.props.history.location.pathname.substr(0, path.length) !== path) ||
                (exact && this.props.history.location.pathname !== path)
                ) {
                this.props.history.push(path)
            }
        }
    }

    onGoBack = (path: string, exact: boolean = true) => {
        if (this.props.history) {
            this.props.history.goBack()
        }
    }

    render() {
        return <></>
    }
}

export default withRouter(ConnectedLayout)
