import { observable, action, computed, observe, reaction } from 'mobx'
import { ImportantEvent } from './important-event'
import { Connection } from '../communication'
import { SecurityStore } from '../security'
import * as LocalStorage from 'store2'

export class ImportantEventStore {
    protected connection: Connection
    protected securityStore: SecurityStore
    @observable events: ImportantEvent[] = []
    @observable filteredForUser = true
    @observable connectedLogin = 'unknown'

    constructor (connection: Connection, securityStore: SecurityStore) {
        this.connection = connection
        this.securityStore = securityStore

        this.connection.on('importantEvent.create', this.onCreate)
        this.connection.on('importantEvent.send', this.onSend)
        this.connection.on('importantEvent.not_send', this.onNotSend)

        reaction(
            () => this.securityStore.isAuthenticated && this.connection.connected,
            this.onConnectToServer
        )

        observe(this.securityStore, 'currentUser', this.onLogin)

        LocalStorage.each((key, value) => {
            const reg = new RegExp(/^importantEvent\.[0-9.]+$/)
            if (key && reg.test(key)) {
                this.onLoadFromStorage(value)
            }
        })
    }

    @computed
    get notSend () {
        let c = 0
        for (const event of this.events) {
            c +=  (event.sendAt === null) ? 1 : 0
        }

        return c
    }

    @computed
    get notSendForCurrentUser () {
        let c = 0
        for (const event of this.events) {
            c +=  (event.sendAt === null && this.securityStore.currentUser && event.by === this.securityStore.currentUser.login) ? 1 : 0
        }

        return c
    }

    @action.bound
    onLoadFromStorage (result: string | null) {
        if (result) {
            try {
                const event = JSON.parse(result)
                const ie = new ImportantEvent()
                ie.load(event)
                this.events.push(ie)
            } catch (error) {
                // Do nothing
            }
        }
    }

    @action
    setFilteredForUser(filteredForUser: boolean) {
        this.filteredForUser = filteredForUser
    }

    @action.bound
    onLogin () {
        this.connectedLogin = this.securityStore.currentUser ? this.securityStore.currentUser.login : 'unknown'
    }

    @action.bound
    onConnectToServer () {
        for (const event of this.events) {
            if (!event.sendAt) {
                this.connection.emitImportant(event)
            }
        }
    }

    @action
    purge () {
        for (let i = this.events.length -1; i >= 0; i--) {
            const event = this.events[i]
            if (event.sendAt !== null && this.securityStore.currentUser && event.by === this.securityStore.currentUser.login) {
                this.eraseEvent(event)
                this.events.splice(i, 1)
            }
        }
    }

    onCreate = (event: ImportantEvent) => {
        event.by = this.connectedLogin
        this.events.push(event)
        this.saveEvent(event)
    }

    onSend = (event: ImportantEvent) => {
        event.sendAt = (new Date()).toISOString()
        event.sendBy = this.connectedLogin
        this.saveEvent(event)
    }

    onNotSend = (event: ImportantEvent) => {
        // Do nothing
    }

    saveEvent (event: ImportantEvent) {
        LocalStorage.set(this.getKey(event), JSON.stringify(event))
    }

    eraseEvent (event: ImportantEvent) {
        LocalStorage.remove(this.getKey(event))
    }

    getKey(event: ImportantEvent) {
        return 'importantEvent.' + event.uuid
    }

    findEventByUuid (uuid: string): ImportantEvent | null {
        for (const event of this.events) {
            if (event.uuid === uuid) {
                return event
            }
        }

        return null
    }
}
