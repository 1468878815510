import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { dateFormat } from '../components/date-formatter'
import { Link } from '../components'
import { generateTextAction } from '../util/actions'
import {
    Badge,
    Button,
    Col, Row,
    Card, CardTitle, CardHeader, CardFooter, CardBody,
    ListGroup, ListGroupItem,
    Spinner
} from 'reactstrap'
import { GuestStore } from '../guest'
import { Guest, Action } from '../guest/guest'
import { SecurityStore } from '../security'
import { ArrivalListItemFrom, DepartureListItemTo } from '../travel'

interface Props {
    guestStore?: GuestStore;
    security?: SecurityStore;
}

interface State {
    reference: string;
}

@inject('guestStore', 'security')
@observer
export default class Home extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props)

        this.state = {
            reference: dateFormat((new Date()).toISOString(), 'yyyy-MM-dd HH:mm:00')
        }
    }

    render() {
        const { guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        const travelGranted = security.isGranted('guest.data.travel')
        const messageGranted = security.isGranted('guest.messageRead')
        const managementGranted = security.isGranted('guest.data.management')

        if (!guestStore.ready) {
            return (
                <div className="text-center m-5 p-5">
                    <Spinner />
                </div>
            )
        }

        const guests = guestStore.guests

        const nbInvites = guests.length
        const nbPresents = guests.filter((guest) => {
            return guest.isPresent
        }).length

        const nextArrivals = guestStore.getNextArrivals(this.state.reference)
        const nextDepartures = guestStore.getNextDepartures(this.state.reference)
        const lastActions = guestStore.getLastActions()

        const by = security.currentUser ? security.currentUser.login : ''
        let nbUnread = 0
        let nbMessages = 0
        for (const guest of guestStore.guests.slice(0)) {
            if (guest.messages.length > 0) {
                for (const message of guest.messages) {
                    if (!guest.isMessageReadBy(message, by)) {
                        nbUnread ++
                    }
                    nbMessages ++
                }
            }
        }

        let guestCounterColor = 'danger'
        if (nbPresents > 0) {
            if (nbPresents < nbInvites / 2) {
                guestCounterColor = 'warning'
            }
            if (nbPresents === nbInvites) {
                guestCounterColor = 'success'
            }
        }

        return (
            <Row className="mt-4 row-cols-2">
                <Col className="mb-4">
                    <Card>
                        <CardHeader className="d-flex justify-content-between align-items-center">
                            <div>
                                <i className="mdi mdi-account-multiple mr-3"></i>
                                Invités
                            </div>
                            <Link
                                to='/guest'
                                className="btn btn-secondary"
                                >
                                <i className="mdi mdi-format-list-bulleted-square"></i>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-center align-items-center">
                                Acceuilli par la team invité :
                                <h1>
                                    <Badge
                                        className="ml-2"
                                        color={guestCounterColor}
                                        >
                                        { nbPresents }
                                    </Badge>
                                    <span className="mx-2">/</span>
                                    <Badge color="primary">
                                        { nbInvites }
                                    </Badge>
                                </h1>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                { messageGranted && (
                    <Col className="mb-4">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <div>
                                    <i className="mdi mdi-email mr-3"></i>
                                    Messages
                                </div>
                                <Link
                                    to='/message'
                                    className="btn btn-secondary"
                                    >
                                    <i className="mdi mdi-email-multiple-outline"></i>
                                </Link>
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex justify-content-center align-items-center">
                                    Non lu
                                    <h1>
                                        <Badge
                                            className="ml-2"
                                            color={nbUnread === nbMessages ? 'success' : 'info'}
                                            >
                                            { nbUnread }
                                        </Badge>
                                        <span className="mx-2">/</span>
                                        <Badge color="primary">
                                            { nbMessages }
                                        </Badge>
                                    </h1>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
                { travelGranted && (
                    <>
                        <Col className="mb-4">
                            <Card>
                                <CardHeader className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i className="mdi mdi-arrow-right-bold"></i>
                                        <i className="mdi mdi-train-car mr-2"></i>
                                        Prochaines arrivées
                                    </div>
                                    <Button
                                        onClick={() => this.refreshReference()}
                                        >
                                        <i className="mdi mdi-refresh"></i>
                                    </Button>
                                </CardHeader>
                                <ListGroup flush>
                                    { nextArrivals.map((guest) => (
                                        <ListGroupItem
                                            key={'home.arrival.'+guest.id}
                                            className="d-flex justify-content-between"
                                            >
                                            <div>
                                                <ArrivalListItemFrom guest={guest} showDay={true}/>
                                            </div>
                                            <div>
                                                {guest.name}
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col className="mb-4">
                            <Card>
                                <CardHeader className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i className="mdi mdi-arrow-left-bold"></i>
                                        <i className="mdi mdi-train-car mr-2"></i>
                                        Prochaines départs
                                    </div>
                                    <Button
                                        onClick={() => this.refreshReference()}
                                        >
                                        <i className="mdi mdi-refresh"></i>
                                    </Button>
                                </CardHeader>
                                <ListGroup flush>
                                    { nextDepartures.map((guest) => (
                                        <ListGroupItem
                                            key={'home.departure.'+guest.id}
                                            className="d-flex justify-content-between"
                                            >
                                            <div>
                                                <DepartureListItemTo guest={guest} showDay={true} />
                                            </div>
                                            <div>
                                                {guest.name}
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Card>
                        </Col>
                    </>
                )}
                { managementGranted && (
                    <Col className="mb-4" sm={12}>
                        <Card>
                            <CardHeader>
                                <i className="mdi mdi-flash mr-3"></i>
                                Dernières actions
                            </CardHeader>
                            <ListGroup flush>
                                { lastActions.map(({action, guest}) => (
                                    <ListGroupItem
                                        key={'home.actions.'+Math.round(Math.random()*10000)}
                                        className="d-flex"
                                        >
                                        <i className="mdi mdi-flash"></i>
                                        <div className="mr-5">
                                            {dateFormat(action.at)}
                                        </div>
                                        <div>
                                            {generateTextAction(action, guest)}
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </Card>
                    </Col>
                )}
            </Row>
        )
    }

    refreshReference () {
        this.setState({
            reference: dateFormat((new Date()).toISOString(), 'yyyy-MM-dd HH:mm:00'),
        })
    }
}
