import * as io from 'socket.io-client'
import { ImportantEvent } from '../important-event/important-event'
import { observable, action } from 'mobx'

export class Connection {
    socket: SocketIOClient.Socket | null = null
    listeners: {
        event: string;
        listener: (...args: any[]) => void;
    }[] = []
    eventNames: string[] = []
    @observable connected: boolean = false

    constructor (uri: string) {
        this.socket = io(uri)

        this.socket.on('connect', action(() => {
            console.log('connect')
            this.connected = true
        }))
        this.socket.on('disconnect', action(() => {
            console.log('disconnect')
            this.connected = false
        }))
    }

    on (event: string, listener: (...args: any[]) => void) {
        this.listeners.push({
            event: event,
            listener: listener
        })

        if (!this.hasEvent(event)) {
            this.eventNames.push(event)
            if (this.socket) {
                this.socket.on(event, (...args: any[]) => {
                    this.dispatch(event, ...args)
                })
            }
        }
    }

    off (event: string, listener: () => void) {
        let d = ''
        for (const l in this.listeners) {
            const k = this.listeners[l]
            if (k.event === event && k.listener === listener) {
                d = l
            }
        }

        if (d !== '') {
            this.listeners.splice(parseInt(d, 10), 1)
        }
    }

    dispatch (event: string, ...args: any[]) {
        for (const k of this.listeners) {
            if (k.event == event) {
                k.listener(...args)
            }
        }
    }

    emit(event: string, ...args: any[]) {
        if (this.socket) {
            this.socket.emit(event, ...args)
        }
    }

    emitImportant(event: string | ImportantEvent, ...args: any[]) {
        let ie: ImportantEvent | null = null
        if ((event instanceof ImportantEvent)) {
            ie = event
        } else {
            ie = new ImportantEvent(event, args)
            this.dispatch('importantEvent.create', ie)
        }

        if (this.connected && ie !== null) {
            this.emit(ie.event, ...ie.args, ie.at)
            this.dispatch('importantEvent.send', ie)
        } else {
            this.dispatch('importantEvent.not_send', ie)
        }
    }

    hasEvent(event: string) {
        return this.eventNames.indexOf(event) >= 0
    }
}
