import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import {
    Button,
    Col, Row,
    Card, CardHeader, CardBody, CardFooter,
    Form, FormGroup, Input
} from 'reactstrap'
import { SecurityStore } from '../security'

interface Props {
    security?: SecurityStore;
}

interface State {
    login: string;
    loginError: boolean;
    password: string;
    passwordError: boolean;
    redirect: boolean;
}

@inject('security')
@observer
export default class UserCreate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            login: '',
            loginError: false,
            password : '',
            passwordError: false,
            redirect: false,
        }
    }

    render() {
        const { login, loginError, password, passwordError, redirect } = this.state

        if (redirect) {
            return (
                <Redirect to="/system/user"/>
            )
        }

        return (
            <Form onSubmit={this.onSubmitHandler} className="mt-4">
                <Row>
                    <Col className="mb-4">
                        <Card>
                            <CardHeader>
                                Identité
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Input
                                        invalid={loginError}
                                        placeholder="Login"
                                        value={login}
                                        onChange={this.onLoginChangeHandler}
                                        />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        invalid={passwordError}
                                        placeholder="Mot de passe"
                                        value={password}
                                        onChange={this.onPasswordChangeHandler}
                                        />
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    color="primary"
                                    >
                                    <i className="mdi mdi-account-plus"></i>
                                    Créer
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Form>
        )
    }

    onLoginChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            login: event.target.value
        })
    }

    onPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: event.target.value
        })
    }

    onSubmitHandler = (event: React.FormEvent) => {
        event.preventDefault()
        const s = {
            loginError: false,
            passwordError: false,
            redirect: false,
        }

        s.loginError = !this.state.login
        s.passwordError = !this.state.password

        if (!s.loginError && !s.passwordError) {
            s.redirect = true
            if (this.props.security) {
                this.props.security.createUser(this.state.login, this.state.password)
            }
        }

        this.setState(s)
    }
}
