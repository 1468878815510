import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Button,
    Card, CardHeader, CardBody,
    ListGroup, ListGroupItem,
    Form, FormGroup, Label, Input, FormFeedback,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Navbar, NavbarBrand,
    Spinner
} from 'reactstrap'
import { Link } from '../components'
import { Navigator } from '../navigation'
import { CommunicationActions } from '../communication/actions'
import { SecurityStore, User } from '../security'
import { NotificationStore } from '../notification'
import cn from 'classnames'

interface Props {
    navigator?: Navigator;
    security?: SecurityStore;
    notificationStore?: NotificationStore;
    title: string
}

interface State {
    password: string;
    passwordError: boolean;
    status: 'waiting' | 'pending' | 'error' | 'success';
}

@inject('security', 'notificationStore', 'navigator')
@observer
export default class AuthLayout extends React.Component<Props, State> {
    protected passwordInput: React.RefObject<HTMLInputElement>
    constructor (props: Props) {
        super(props)

        this.state = {
            status: 'waiting',
            password: '',
            passwordError: false,
        }

        if (this.props.navigator) {
            this.props.navigator.on('key-up', this.onKeyUp)
        }

        this.passwordInput = React.createRef<HTMLInputElement>()
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyUp = (code: string) => {
        if (code === 'Escape' && this.props.security && this.props.security.login) {
            this.props.security.setLogin('')
            return
        }

        if (this.props.security && !this.props.security.login) {
            const matches = code.match(/^Digit([0-9])$/)
            if (matches) {
                const index: number = parseInt(matches[1])
                if (index <= this.props.security.users.length) {
                    const users = this.orderedUsers(this.props.security.users)
                    this.props.security.setLogin(users[index-1].login)
                }
            }
        }
    }

    componentDidUpdate() {
        if (this.passwordInput.current) {
            this.passwordInput.current.focus()
        }
    }

    render() {
        const { security } = this.props

        if (!security) {
            return null
        }

        const nbUsers = security.users ? security.users.length : 0

        const users = this.orderedUsers(security.users)

        const modal = (
            <Modal isOpen={security.error !== null} toggle={this.clearError}>
                <ModalHeader toggle={this.clearError}>
                    Erreur
                </ModalHeader>
                <ModalBody className="text-danger">
                    { security.error }
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <Button
                        onClick={this.clearError}
                        >
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        )

        let content = <></>

        if (!security.login) {
            content = (
                <div className="m-5 d-flex justify-content-center align-items-center flex-column">
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <i className="mdi mdi-lock mdi-48px"></i>
                                </div>
                                <div className="mx-5">
                                    <h1>Qui êtes vous ?</h1>
                                </div>
                                <CommunicationActions />
                            </div>
                        </CardHeader>
                        { nbUsers > 0 ? (
                            <ListGroup flush>
                                {users.map((user: User) => (
                                    <ListGroupItem
                                        key={user.id ? user.id : user.login}
                                        action
                                        onClick={() => security.setLogin(user.login)}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <div className={user.login === security.lastLogin ? 'text-info' : 'text-muted'}>
                                            <i className="mdi mdi-account"></i>
                                        </div>
                                        <div className={user.login === security.lastLogin ? 'text-info' : 'text-muted'}>
                                            {user.login}
                                        </div>
                                        <div className={user.login === security.lastLogin ? 'text-info' : 'text-muted'}>
                                            <i className="mdi mdi-login"></i>
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        ) : (
                            <div className="d-flex justify-content-center m-5">
                                <Spinner />
                            </div>
                        )}
                    </Card>
                </div>
            )
        } else {
            content = (
                <div className="m-5 d-flex justify-content-center align-items-center flex-column">
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <Button size="lg" onClick={() => security.setLogin('')}>
                                        <i className="mdi mdi-arrow-left-bold"></i>
                                    </Button>
                                </div>
                                <div className="mx-5">
                                    <h1>{security.login} ? Prouvez le !</h1>
                                </div>
                                <CommunicationActions />
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form
                                onSubmit={this.onSubmitHandler}
                            >
                                <FormGroup>
                                    <Label for="password">
                                        Mot de passe
                                    </Label>
                                    <input
                                        className={cn('form-control', { 'is-invalid': this.state.passwordError})}
                                        type="password"
                                        name="password"
                                        onChange={this.onChangePasswordHandler}
                                        ref={this.passwordInput}
                                        />
                                </FormGroup>
                                { this.state.status === 'error' && (
                                    <div className="alert alert-danger">
                                        Impossible de se connecter avec ce mot de passe
                                    </div>
                                )}
                                <Button
                                    type="submit"
                                    color="primary"
                                    block
                                    className="text-white"
                                    disabled={this.state.status === 'pending'}
                                >
                                    Envoyer
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            )
        }

        return (
            <>
                <Navbar color="primary" dark expand="md">
                    <Link to="/" className="navbar-brand">{ this.props.title }</Link>
                </Navbar>
                { modal }
                { content }
            </>
        )
    }

    public onChangePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: event.target.value
        })
    }

    public onSubmitHandler = (event: React.FormEvent) => {
        event.preventDefault()

        const s: State = Object.assign({}, this.state)

        if (s.password.length === 0) {
            s.passwordError = true
        } else {
            s.passwordError = false
        }

        this.setState(s)

        if (!s.passwordError && this.props.security) {
            this.props.security.setPassword(s.password, true)
        }
    }

    orderedUsers (users: User[]): User[] {
        const _users = users.slice(0)

        _users.sort((a, b) => {
            if (a.login.toLowerCase() == b.login.toLowerCase()) {
                return 0
            }

            return a.login.toLowerCase() < b.login.toLowerCase() ? -1 : 1
        })

        return _users
    }

    clearError = () => {
        if (this.props.security) {
            this.props.security.clearError()
        }
    }
}
