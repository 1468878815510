import '../css/app.scss'

import * as React from 'react'
import { hydrate } from 'react-dom'
import Bootstrap from './bootstrap'
import { BrowserRouter } from 'react-router-dom'

declare global {
    interface Window {
        __ENV__: {
            'API_ENDPOINT': string;
            'API_PUBLIC_KEY': string;
            'APP_TITLE': string;
            'APP_START_FESTIVAL': string;
            'APP_NB_DAYS_BEFORE': number;
            'APP_NB_DAYS_LONG': number;
            'APP_NB_DAYS_AFTER': number;
        }
    }
}

hydrate(
    <BrowserRouter>
        <Bootstrap
            apiEndpoint={window.__ENV__.API_ENDPOINT ? window.__ENV__.API_ENDPOINT : ''}
            apiPublicKey={window.__ENV__.API_PUBLIC_KEY ? window.__ENV__.API_PUBLIC_KEY : ''}
            appTitle={window.__ENV__.APP_TITLE ? window.__ENV__.APP_TITLE :'Guest Manager'}
        />
    </BrowserRouter>,
    document.getElementById('app') as Element
)