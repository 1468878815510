import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Badge,
    Button,
    CustomInput,
    Table
} from 'reactstrap'
import { ImportantEventListItem } from './list-item'
import { ImportantEventStore } from './store'
import { ImportantEvent } from './important-event'

interface Props {
    importantEventStore?: ImportantEventStore;
}

@inject('importantEventStore')
@observer
export default class ImportantEventList extends React.Component<Props, {}> {
    render() {
        const { importantEventStore } = this.props

        if (!importantEventStore) {
            return null
        }

        const events = this.filterAndOrderedEvents(importantEventStore.events)

        return (
            <div className="mt-4">
                <div className="d-flex flex-row flex-wrap justify-content-around m-1">
                    <div className="d-flex align-items-center">
                        <span className="mr-2">Toutes les actions : </span>
                        <CustomInput
                            id={`checklist.allActions`}
                            type="switch"
                            checked={!importantEventStore.filteredForUser}
                            onChange={this.onChangeFilterHandler}
                            />
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-2">Total liste : </span>
                        <Badge color="info">
                            <span>{events.length}</span>
                        </Badge>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-2">Total non envoyé : </span>
                        <Badge color="danger">
                            <span>{importantEventStore.filteredForUser ? importantEventStore.notSendForCurrentUser : importantEventStore.notSend}</span>
                        </Badge>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button color="primary" onClick={() => importantEventStore.purge()}>
                            <i className="mdi mdi-trash-can-outline"></i>
                            <span>Purger</span>
                        </Button>
                    </div>
                </div>
                { events.length > 0 && (
                <div>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Action
                                </th>
                                <th>
                                    Création
                                </th>
                                <th>
                                    Synchronisation
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { events.map((event: ImportantEvent) => (
                                <ImportantEventListItem key={event.uuid} event={event} />
                            ))}
                        </tbody>
                    </Table>
                </div>
                )}
            </div>
        )
    }

    onChangeFilterHandler = () => {
        if (this.props.importantEventStore) {
            this.props.importantEventStore.setFilteredForUser(!this.props.importantEventStore.filteredForUser)
        }
    }

    filterAndOrderedEvents (events: ImportantEvent[]) {
        let _events = events.slice(0)


        if (this.props.importantEventStore && this.props.importantEventStore.filteredForUser) {
            _events = _events.filter((event) => {
                if (this.props.importantEventStore) {
                    return event.by == this.props.importantEventStore.connectedLogin
                }
                return false
            })
        }

        _events.sort((a: ImportantEvent, b: ImportantEvent) => {
            if (a.at === b.at) {
                return 0
            }

            if (a.at === null) {
                return 1
            }

            if (b.at === null) {
                return -1
            }

            return a.at > b.at ? -1 : 1
        })

        return _events.slice(0, 100)
    }
}
