import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
    title: (params: any) => string | string;
}

export class TitleItem extends React.Component<Props, {}> {
    render() {
        const { children, path, exact , title, ...args } = this.props
        return (
            <Route
                path={path}
                exact={exact}
                render={({match}) => (
                <span style={{
                    marginLeft: 0
                  }}
                > &#62; {(typeof title === 'function') ? title(match.params) : title}</span>
                )}
              />
        )
    }
}
