import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '../components/link'
import {
    Badge,
    DropdownToggle, DropdownMenu, DropdownItem ,
    UncontrolledDropdown
} from 'reactstrap'
import { ImportantEventStore } from '../important-event'
import { GuestStore } from '../guest'
import { SecurityStore } from '../security'

interface Props {
    importantEventStore?: ImportantEventStore;
    guestStore?: GuestStore;
    security?: SecurityStore;
}

@inject('importantEventStore', 'security', 'guestStore')
@observer
export default class SystemFooter extends React.Component<Props, {}> {
    render() {
        const { importantEventStore, guestStore, security } = this.props

        if (!importantEventStore || !guestStore || !security) {
            return null
        }

        const nbNotifications = importantEventStore.notSendForCurrentUser

        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <i className="mdi mdi-cogs mr-2"></i>
                    Système
                    {nbNotifications > 0 && (
                        <Badge className="ml-2">
                            {nbNotifications}
                        </Badge>
                    )}
                </DropdownToggle>
                <DropdownMenu>
                    <Link
                        access="user"
                        to="/system/user"
                        dropdown={true}
                        >
                        <i className="mdi mdi-account mr-2"></i>
                        Utilisateurs
                    </Link>
                    <Link
                        to="/system/important-event"
                        dropdown={true}
                        >
                        <i className="mdi mdi-pulse mr-2"></i>
                        Mes actions
                        {nbNotifications > 0 && (
                            <Badge className="ml-2" style={{
                                alignSelf: "flex-start",
                                top: -20,
                                right: -10,
                            }}>
                                {nbNotifications}
                            </Badge>
                        )}
                    </Link>
                    { security.isGranted('guest.fetch') && (
                        <>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => guestStore.refresh()}
                                >
                                <i className="mdi mdi-refresh mr-2"></i>
                                Mettre à jour le serveur
                            </DropdownItem>
                        </>
                   )}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
}

