import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { SecurityStore } from '../security'

interface Props {
    security?: SecurityStore;
    access: string;
    replace?: any;
}

@inject('security')
@observer
export class SecuredView extends React.Component<Props, {}> {
    render () {
        const { security, access, replace, children, ...args } = this.props

        if (access && security && !security.isGranted(access)) {
            if (replace) {
                return (
                    <div {...args}>
                        {replace instanceof React.Component ? replace : (<span>{replace}</span>)}
                    </div>
                )
            } else {
                return (
                    <div {...args} />
                )
            }
        }

        return (
           <div {...args}>
               {children}
           </div>
        )
    }
}
