import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
} from 'reactstrap'
import { Guest } from '../guest'
import cn from 'classnames'
import { getDay } from '../util/days'

interface Props {
    guest: Guest;
    showDay?: boolean;
}

@observer
export default class ArrivalListItemFrom extends React.Component<Props, {}> {
    render() {
        const { guest, showDay } = this.props

        if (!guest.data.travel) {
            return null
        }

        return (
            <>
                { guest.data.travel.arrival.type === 'independent' && (
                    <span className={!guest.isArrivalManaged ? 'text-info' : ''}>
                        <i className="mdi mdi-car mr-2"></i>
                    </span>
                )}
                { guest.data.travel.arrival.type === 'train' && (
                    <span className={!guest.isArrivalManaged ? 'text-success' : ''}>
                        <i className="mdi mdi-train mr-2"></i>
                    </span>
                )}
                { guest.data.travel.arrival.type === 'plane' && (
                    <span className={!guest.isArrivalManaged ? 'text-danger' : ''}>
                        <i className="mdi mdi-airplane mr-2"></i>
                    </span>
                )}
                { showDay && (
                    <span className="mr-2">
                        { guest.data.travel.arrival.day ? getDay(guest.data.travel.arrival.day) : '' }
                    </span>
                )}
                <span>
                    { guest.data.travel.arrival.end ? guest.data.travel.arrival.end : '?' } | { guest.data.travel.arrival.to ? guest.data.travel.arrival.to : '?' }
                </span>
                { guest.data.travel.arrival.start != '' && (
                    <span className="ml-2 text-muted">
                        ({ guest.data.travel.arrival.from } {guest.data.travel.arrival.start })
                    </span>
                )}
            </>
        )
    }
}
