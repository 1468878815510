import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Badge,
    Table
} from 'reactstrap'
import { Navigator } from '../navigation'
import { DepartureListItem } from './departure-list-item'
import { GuestStore, Guest } from '../guest'
import { getDay, departureDays } from '../util/days'

interface Props {
    navigator?: Navigator;
    guestStore?: GuestStore;
    identityGranted: boolean;
    departureManagedGranted: boolean;
    receptionGranted: boolean;
    managementGranted: boolean;
    guestShowGranted: boolean;
    day: string;
}

@inject('guestStore', 'navigator')
@observer
export class DepartureListTab extends React.Component<Props, {}> {
    constructor (props: Props) {
        super(props)

        if (this.props.navigator) {
            this.props.navigator.on('key-down', this.onKeyDown)
            this.props.navigator.on('key-up', this.onKeyUp)
        }
    }

    componentWillUnmount () {
        if (this.props.navigator) {
            this.props.navigator.off('key-down', this.onKeyDown)
            this.props.navigator.off('key-up', this.onKeyUp)
        }
    }

    onKeyDown = (code: string, event: KeyboardEvent) => {
        switch (event.code) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
            event.preventDefault()
            break
        }
    }

    onKeyUp = (code: string) => {
        if (!this.props.navigator) {
            return
        }

        let max: number
        let selectedIndex: number

        switch(code) {
        case 'Enter':
            if (this.props.guestStore) {
                const departures = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day)

                if (this.props.guestStore.selectedIndex !== null && this.props.guestStore.selectedIndex >= 0 && this.props.guestStore.selectedIndex < departures.count) {
                    this.props.navigator.redirectTo('/guest/' + departures.list[this.props.guestStore.selectedIndex].id)
                    break
                }

                if (departures.count === 1) {
                    this.props.navigator.redirectTo('/guest/' + departures.list[0].id)
                }
            }
            break
        case 'Home':
            if (this.props.guestStore) {
                this.props.guestStore.setSelectedIndex(0)
            }
            break
        case 'End':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                this.props.guestStore.setSelectedIndex(max)
            }
            break
        case 'ArrowUp':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                selectedIndex = Math.max(0, this.props.guestStore.selectedIndex === null ? max : this.props.guestStore.selectedIndex - 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        case 'ArrowDown':
            max = 0
            if (this.props.guestStore) {
                max = this.filterAndOrderedGuests(this.props.guestStore.guests, this.props.day).count - 1
                selectedIndex = Math.min(max, this.props.guestStore.selectedIndex === null ? 0 : this.props.guestStore.selectedIndex + 1)
                this.props.guestStore.setSelectedIndex(selectedIndex)
            }
            break
        }
    }

    render() {
        const { guestStore, identityGranted, departureManagedGranted, receptionGranted, managementGranted, guestShowGranted, day } = this.props

        if (!guestStore) {
            return null
        }

        const departures = this.filterAndOrderedGuests(guestStore.guests, day)

        return (
            <div>
                <div className="d-flex flex-row justify-content-around">
                    <h2>
                        Total
                        <Badge color="info" className="ml-3">
                            {departures.count}
                        </Badge>
                    </h2>
                    <h2>
                        Parti
                        <Badge color={departures.count == departures.nbManaged ? 'success' : 'primary'} className="ml-3">
                            {departures.nbManaged}
                        </Badge>
                    </h2>
                    <h2>
                        A venir
                        <Badge color={departures.count - departures.nbManaged >0 ? 'warning' : 'success'} className="ml-3">
                            {departures.count - departures.nbManaged}
                        </Badge>
                    </h2>
                </div>
                { departures.count === 0 ? (
                    <div className="alert alert-success m-5 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-trophy mdi-48px mr-5"></i>
                        Tous les départs (qui comptent) de la journée sont gérés... quelle équipe formidable !!!
                    </div>
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    Voyage
                                </th>
                                <th>
                                    Identité
                                </th>
                                <th>
                                    Nom de scène
                                </th>
                                <th>
                                    Groupe
                                </th>
                                { receptionGranted && (
                                    <th>
                                        Hébergement
                                    </th>
                                )}
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { departures.list.map((guest: Guest, index: number) => (
                                <DepartureListItem
                                    key={index}
                                    guest={guest}
                                    identityGranted={identityGranted}
                                    departureManagedGranted={departureManagedGranted}
                                    receptionGranted={receptionGranted}
                                    managementGranted={managementGranted}
                                    guestShowGranted={guestShowGranted}
                                    showDay={day === 'inconnu'}
                                    selected={guestStore.selectedIndex === index}
                                    />
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        )
    }

    filterAndOrderedGuests (guests: Guest[], day: string) {

        const _guests = guests.filter((guest) => {
            if (guest.data.management.cancellation || !guest.data.travel) {
                return false
            }
            let key = guest.data.travel.departure.day ? getDay(guest.data.travel.departure.day) : 'inconnu'
            if (!key) {
                key = 'inconnu'
            }

            if (departureDays.indexOf(key) < 0) {
                key = 'inconnu'
            }

            let isOpen = true

            if (this.props.guestStore) {
                if (guest.isDepartureManaged && !this.props.guestStore.showManagedDepartures) {
                    isOpen = false
                }

                if (guest.data.travel.arrival.type === 'independent' && !this.props.guestStore.showIndependentDepartures) {
                    isOpen = false
                }
            }

            if (!isOpen) {
                return null
            }
            return key == day
        })

        let nbManaged = 0
        _guests.map((guest) => {
            if (guest.isDepartureManaged) {
                nbManaged ++
            }
        })

        _guests.sort((a, b) => {
            if (a.data.travel.departure.start == b.data.travel.departure.start) {
                return 0
            }

            if (!a.data.travel.departure.start) {
                return 1
            }

            if (!b.data.travel.departure.start) {
                return -1
            }

            return a.data.travel.departure.start < b.data.travel.departure.start ? -1 : 1
        })

        return {
            list: _guests,
            nbManaged: nbManaged,
            count: _guests.length
        }
    }
}
