import * as React from 'react'
import { dateFormat } from '../components/date-formatter'
import { Link } from 'react-router-dom'
import {
    Button
} from 'reactstrap'
import { Guest } from '../guest'
import cn from 'classnames'

interface Props {
    nbUnread: number;
    nbMessages: number;
    guest: Guest;
    lastAt: Date | null;
    selected: boolean;
}

export default class MessageListItem extends React.Component<Props, {}> {
    render() {
        const { nbUnread, nbMessages, guest, lastAt, selected } = this.props

        return (
            <tr className={cn({ 'table-active': selected })}>
                <td>
                    <i className={ nbUnread === 0 ? 'mdi mdi-email-open-outline mr-2' : 'mdi mdi-email-outline mr-2' }></i>
                    <span className={nbUnread > 0 ? 'font-weight-bold' : ''}>{nbUnread ? nbUnread : 0} non lu(s) / {nbMessages ? nbMessages : 0}</span>
                </td>
                <td>
                    Conversation à propos de {guest.name}
                </td>
                <td>
                    <div className="d-flex justify-content-end">
                        <Link
                            className="btn btn-secondary"
                            to={'/guest/'+guest.id}
                            >
                            <i className="mdi mdi-book-open-variant"></i>
                        </Link>
                    </div>
                </td>
            </tr>
        )
    }
}
