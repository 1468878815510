import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { SecurityStore } from '../security'

interface Props extends RouteProps {
    security?: SecurityStore;
    access?: string;
    redirect?: boolean;
}

@inject('security')
@observer
export class SecuredRoute extends React.Component<Props, {}> {
    render () {
        const { security, access, redirect, ...args } = this.props

        if (access && security && !security.isGranted(access)) {
            if (redirect) {
                return (
                    <Redirect to="/" />
                )
            } else {
                return null
            }
        }

        return (
           <Route {...args} />
        )
    }
}
