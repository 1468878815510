import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import UserList from './list'
import UserCreate from './create'
import UserEdit from './edit'

export default class UserScreen extends React.Component {
    render() {

        return (
            <Switch>
                <Route exact path="/system/user" component={UserList} />
                <Route exact path="/system/user/create" component={UserCreate} />
                <Route exact path="/system/user/edit/:id" component={UserEdit} />
            </Switch>
        )
    }
}
