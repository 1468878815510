import * as React from 'react'
import { observer, inject } from 'mobx-react'
import {
    Card, CardHeader, CardBody,
    Col,
    CustomInput,
    ListGroup, ListGroupItem
} from 'reactstrap'
import GuestShowMessage from './show_message'
import { SecurityStore } from '../security'
import { GuestStore } from './store'
import { Guest } from './guest'

interface Props {
    security?: SecurityStore;
    guestStore?: GuestStore;
    guest: Guest;
}

@inject('security', 'guestStore')
@observer
export default class GuestShowManagement extends React.Component<Props, {}> {
    render() {
        const { guest, guestStore, security } = this.props

        if (!guestStore || !security) {
            return null
        }

        const managementGranted = security.isGranted('guest.data.management')
        const messageGranted = security.isGranted('guest.message')
        const presentGranted = security.isGranted('guest.present')

        if (!managementGranted) {
            return null
        }

        return (
            <Col className="mb-3">
                <Card>
                    <CardHeader>
                        <i className="mdi mdi-information-circle-outline mr-2"></i>
                        Gestion
                    </CardHeader>
                    <ListGroup flush>
                        { guest.data.management.cancellation && (
                            <ListGroupItem className="text-danger">
                                <i className="mdi mdi-alert-box mr-5"></i>
                                Cet invité ne sera finalement pas présent sur le festival !
                            </ListGroupItem>
                        )}
                        <ListGroupItem>
                            <b>Responsable : { guest.data.management.manager } ({ guest.data.management.managerPhone })</b>
                        </ListGroupItem>
                        { guest.data.privacy && guest.data.privacy.phone && (
                            <ListGroupItem>
                                Téléphone : { guest.data.privacy.phone }
                            </ListGroupItem>
                        )}
                        <ListGroupItem>
                            Nombre de personnes supplémentaires : { guest.data.management.nbExtraPersons != '' ? guest.data.management.nbExtraPersons : 0 }
                        </ListGroupItem>
                        <ListGroupItem>
                            Badge : { guest.data.management.badge != '' ? 'oui' : 'non' }
                        </ListGroupItem>
                        <ListGroupItem className="d-flex">
                            <span className="mr-5">
                                Accueilli par la team invité :
                            </span>
                            { presentGranted ? (
                                <CustomInput
                                    id={'presence_' + guest.id}
                                    type="switch"
                                    checked={guest.isPresent}
                                    onChange={this.onPresenceChangeHandler}
                                    />
                            ) : (
                                <span className={guest.isPresent ? 'text-success' : 'text-warning' }>
                                    <i className={guest.isPresent ? 'mdi mdi-check' : 'mdi mdi-close' }></i>
                                </span>
                            )}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Point de chute : { guest.data.management.location }</b>
                        </ListGroupItem>
                        <ListGroupItem>
                            Jours de présence : { guest.joursPresence.length > 0 ? guest.joursPresence.join(', ') : '-' }
                        </ListGroupItem>
                        { (guest.data.management.meetAndGreet && guest.data.management.meetAndGreet.length > 0) && (
                            <ListGroupItem>
                                Rencontre VIP :
                                { guest.data.management.meetAndGreet.map((mg: {d: string, h: string}) => (
                                    <>
                                        { mg.d === 'fri' && (
                                            <span className="mx-2">Vendredi { mg.h }</span>
                                        )}
                                        { mg.d === 'sat' && (
                                            <span className="mx-2">Samedi { mg.h }</span>
                                        )}
                                        { mg.d === 'sun' && (
                                            <span className="mx-2">Dimanche { mg.h }</span>
                                        )}
                                    </>
                                ))}

                            </ListGroupItem>
                        )}
                    </ListGroup>
                </Card>
            </Col>
        )
    }

    onPresenceChangeHandler = () => {
        if (this.props.guestStore) {
            this.props.guestStore.markPresent(this.props.guest, !this.props.guest.isPresent)
        }
    }
}
