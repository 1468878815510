const types: {[id: string]: string} = {
    'independent': 'Autonome',
    'train': 'Train',
    'plane': 'Avion',
}

export const translateTravelType = (type: string): string | undefined => {
    for (const t in types) {
        if (type === t) {
            return types[t]
        }
    }
}
