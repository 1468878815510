import { observable, action, computed } from 'mobx'
import { getISODate } from '../util/days'

export class Guest {
    @observable id: string
    @observable name = ''
    @observable stringKey = ''
    @observable isInternal = false
    @observable isPresent = false
    @observable isArrivalManaged = false
    @observable isDepartureManaged = false
    @observable data: {[group: string]: {[key: string]: any}} = {}
    @observable actions: Action[] = []
    @observable messages: Message[] = []
    @observable picture: string = ''
    @observable arrivalDate = ''
    @observable departureDate = ''
    @observable synchronized: boolean = false

    constructor (id: string) {
        this.id = id
    }

    @action.bound
    load (guest: SerializedGuest) {
        const formatHourFields = [
            'arrivee_heure',
            'arrivee_heure_depart',
            'depart_heure',
            'depart_heure_arrivee'
        ]

        for (const t in guest.data) {
            if (!this.data[t]) {
                this.data[t] = {}
            }
            for (const d in guest.data[t]) {
                let v = guest.data[t][d]

                if (t === 'travel' && formatHourFields.indexOf(d) >= 0) {
                    v = Guest.formatHour(v)
                }

                this.data[t][d] = v
            }
        }

        this.isInternal = guest.isInternal || false
        this.isPresent = guest.isPresent
        this.isArrivalManaged = guest.isArrivalManaged
        this.isDepartureManaged = guest.isDepartureManaged
        this.picture = guest.picture
        this.synchronized = guest.synchronized

        if (guest.messages) {
            this.messages = guest.messages
        }

        if (this.data.identity) {
            if (this.data.identity.stageName) {
                this.name = this.data.identity.stageName
            } else {
                this.name = (this.data.identity.lastname + ' ' + this.data.identity.firstname).trim()
            }
        }

        let stringKey = ''
        if (this.data.identity) {
            if (this.data.identity.stageName) {
                stringKey += this.data.identity.stageName
            }

            if (this.data.identity.lastname) {
                stringKey += this.data.identity.lastname
            }

            if (this.data.identity.firstname) {
                stringKey += this.data.identity.firstname
            }

            if (this.data.identity.group) {
                stringKey += this.data.identity.group
            }
        }

        this.stringKey = stringKey.toLowerCase()

        if (this.data.travel) {
            const day = getISODate(this.data.travel.arrival.day ? this.data.travel.arrival.day : '')
            if (day) {
                this.arrivalDate = day+' '+(this.data.travel.arrival.end ? this.data.travel.arrival.end : '?')
            }
        }

        if (this.data.travel) {
            const day = getISODate(this.data.travel.departure.day ? this.data.travel.departure.day.toLowerCase() : '')
            if (day) {
                this.departureDate = day+' '+(this.data.travel.departure.start ? this.data.travel.departure.start : '?')
            }
        }

        if (guest.actions) {
            this.actions.splice(0)
            for (const action of guest.actions) {
                this.actions.push(action)
            }
        }

        return this
    }

    @computed
    get joursPresence () {
        if (!this.data.management) {
            return []
        }

        const j = []
        if (this.data.management.presences) {
            for (const presence of this.data.management.presences) {
                if (presence === 'fri') {
                    j.push('vendredi')
                }
                if (presence === 'sat') {
                    j.push('samedi')
                }
                if (presence === 'sun') {
                    j.push('dimanche')
                }
            }
        }

        return j
    }

    getNbUnreadMessageBy(by: string): number {
        let nb = 0

        for (const message of this.messages) {
            if (!this.isMessageReadBy(message, by)) {
                nb++
            }
        }

        return nb
    }

    isMessageUuidReadBy (uuid: string, by: string): boolean {
        for (const message of this.messages) {
            if (message.uuid === uuid) {
                return this.isMessageReadBy(message, by)
            }
        }
        return false
    }

    isMessageReadBy (message: Message, by: string): boolean {
        if (!message.readBy) {
            return false
        }
        for (const reader of message.readBy) {
            if (reader.by === by) {
                return true
            }
        }
        return false
    }

    static formatHour (hour: string): string {
        const hourReg = new RegExp(/^([0-9]+)(h|H)([0-9]*)$/)
        if (hourReg.test(hour)) {
            const matches = hourReg.exec(hour)
            if (matches && matches.length >= 4) {
                hour = matches[1].padStart(2, '0')+':'+matches[3].padStart(2, '0')
            }
        }

        return hour
    }
}

export interface SerializedGuest {
    id: string;
    data: {[group: string]: {[key: string]: any}};
    isInternal: boolean;
    isPresent: boolean;
    isArrivalManaged: boolean;
    isDepartureManaged: boolean;
    actions: Action[];
    messages: Message[];
    picture: string;
    synchronized: boolean;
}

export type StateKey = 'present' | 'arrivalManaged' | 'departureManaged'

export type ActionType = StateKey | 'picture' | 'messageWrite' | 'messageRead'


export interface Action {
    at: Date;
    type: ActionType;
    by: string;
    data?: {[key: string]: any};
}

export interface Message {
    uuid: string;
    at: Date;
    content: string;
    by: string;
    readBy?: MessageRead[];
}

export interface MessageRead {
    at: Date;
    by: string;
}
