import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import {
    Button,
    Table
} from 'reactstrap'
import { UserListItem } from './list-item'
import { SecurityStore, User } from '../security'

interface Props {
    security?: SecurityStore;
}

@inject('security')
@observer
export default class UserList extends React.Component<Props, {}> {
    render() {
        const { security } = this.props

        if (!security) {
            return null
        }

        const users = this.filterAndOrderedUsers(security.users)

        return (
            <div className="mt-4">
                <div className="d-flex justify-content-end mb-4">
                    <Link
                        to="/system/user/create"
                        className="btn btn-success"
                        >
                        <i className="mdi mdi-account-plus mr-2"></i>
                        Ajouter un utilisateur
                    </Link>
                </div>
                <Table striped>
                    <tbody>
                        { users.map((user: User, index: number) => (
                            <UserListItem key={index} user={user} />
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    filterAndOrderedUsers (users: User[]): User[] {
        const _users = users.slice(0)

        _users.sort((a: User, b: User) => {
            if (a.login.type == b.login.type) {
                return 0
            }

            return a.login.type < b.login.type ? -1 : 1
        })

        return _users
    }
}
