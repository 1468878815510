import * as React from 'react'
import { DateTime } from 'luxon'

export const dateFormat = (date: string | Date, format = 'dd/LL/yyyy T') => {
    if (date instanceof Date) {
        return DateTime.fromJSDate(date).toFormat(format)
    }
    return DateTime.fromISO(date).toFormat(format)
}

interface DateFormatterProps {
    date: Date | string;
    format: string;
}

export const DateFormatter = ({date, format = 'dd/LL/yyyy T', ...otherProps}: DateFormatterProps) => (
    <span {...otherProps}>
        {dateFormat(date, format)}
    </span>
)

