import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { SecurityStore, User } from '../security'

interface Props {
    security?: SecurityStore;
    user: User;
}

interface State {
    trashConfirm: boolean;
}

@inject('security')
@observer
export class UserListItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            trashConfirm: false
        }
    }
    render() {
        const { security, user } = this.props

        return (
            <tr>
                <td>
                    { user.login }
                </td>
                <td>
                    <div className="d-flex flex-rox justify-content-end">
                        {!user.admin && (
                            <>
                                <Link
                                    to={`/system/user/edit/${user.id}`}
                                    className="btn btn-secondary btn-sm"
                                    >
                                    <i className="mdi mdi-account-edit-outline"></i>
                                </Link>
                                <Button
                                    onClick={this.askForTrash}
                                    color="danger"
                                    size="sm"
                                    className="ml-4"
                                    >
                                    <i className="mdi mdi-trash-can-outline"></i>
                                </Button>
                            </>
                        )}
                    </div>
                </td>
                { this.state.trashConfirm && (
                    <Modal isOpen={true} toggle={this.onCloseConfirmHandler}>
                        <ModalHeader toggle={this.onCloseConfirmHandler}>Confirmation</ModalHeader>
                        <ModalBody>
                            {`Etes vous sûr de vouloir supprimer l'utilisateur "${user.login}"`}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.onConfirmHandler}>Oui</Button>{' '}
                            <Button color="secondary" onClick={this.onCloseConfirmHandler}>Non</Button>
                        </ModalFooter>
                    </Modal>
                )}
            </tr>
        )
    }

    askForTrash = () => {
        this.setState({
            trashConfirm: true
        })
    }

    onCloseConfirmHandler = () => {
        this.setState({
            trashConfirm: false
        })
    }

    onConfirmHandler = () => {
        if (this.props.security) {
            this.props.security.removeUser(this.props.user.login)
        }

        this.setState({
            trashConfirm: false
        })
    }
}
